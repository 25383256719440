/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field } from 'formik';
import useStyles from './style';

const CustomAutoComplete = ({
  field,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      variant="outlined"
      fullWidth
      name={field.name}
      size="small"
      renderInput={(params) => <TextField placeholder={props.placeholder} variant="outlined" {...params} />}
      classes={{
        inputFocused: classes.inputFocused,
      }}
      {...props}
    />
  );
};

const AutoCompleteFormik = (props) => (
  <Field
    component={CustomAutoComplete}
    {...props}
  />
);

AutoCompleteFormik.defaultProps = {
  options: [],
  validate: () => {},
};

AutoCompleteFormik.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  validate: PropTypes.func,
};

export default AutoCompleteFormik;
