import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import useStyles from './style';

const SortUpDownCustom = ({
  label,
  size,
  handleClickDown,
  handleClickUp,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <IconButton
        classes={{ sizeSmall: classes.arrow }}
        aria-label={label}
        size={size}
        onClick={() => handleClickUp()}
      >
        <KeyboardArrowUp style={{ height: '0.7em', color: 'grey' }} />
      </IconButton>
      <IconButton
        classes={{ sizeSmall: classes.arrow }}
        aria-label={label}
        size={size}
        onClick={() => handleClickDown()}
      >
        <KeyboardArrowDown style={{ height: '0.7em', color: 'grey' }} />
      </IconButton>
    </Box>
  );
};

SortUpDownCustom.defaultProps = {
  label: '',
  size: 'small',
  handleClickDown: () => {},
  handleClickUp: () => {},
};

SortUpDownCustom.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  handleClickDown: PropTypes.func,
  handleClickUp: PropTypes.func,
};

export default SortUpDownCustom;
