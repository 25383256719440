/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Alert } from '@material-ui/lab';
import { Field } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { api } from '../../api/api';
import ModalCustom from '../../commonComponents/ModalCustom/ModalCustom';
import BodyNewForecasting from '../Forecasting/BodyAddForecasting/BodyNewForecasting';
import CollaboratorProject from './CollaboratorProject';
import ForecastingDates from './ForecastingDates';
import ButtonCustom from '../../commonComponents/ButtonCustom/ButtonCustom';

const useStyles = makeStyles(() => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '185px',
    marginLeft: 0,
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    height: '40px',
  },
  selectSize: {
    height: '48px',
  },
  LabelType: {
    top: '-3px',
  },
  pointFilter: {
    width: '11px',
    height: '11px',
    marginRight: '8px',
  },
  link: {
    color: '#001F33',
  },
}));

const StaffedCustom = ({
  field, form: { touched, errors },
  currentStatus, ...props
}) => {
  const classes = useStyles();
  return (
    <div style={{ marginRight: '32px' }}>
      <Select
        variant="outlined"
        id="fully_staffed"
        name="fully_staffed"
        // displayEmpty
        classes={{ select: classes.select, outlined: classes.outlined }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...field}
        {...props}
      >
        <MenuItem value="2">
          <FiberManualRecordIcon className={classes.pointFilter} id="statusGreen" />
          Fully Staffed
        </MenuItem>
        <MenuItem value="1">
          <FiberManualRecordIcon className={classes.pointFilter} id="statusOrange" />
          Over Staffed
        </MenuItem>
        <MenuItem value="0">
          <FiberManualRecordIcon className={classes.pointFilter} id="statusRed" />
          Under Staffed
        </MenuItem>
      </Select>
      {touched[field.name]
      && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
  );
};
const getFilteredForecasting = (forecastings, weekStart, weekEnd) => _.filter(forecastings,
  (o) => moment(o.date).isBetween(weekStart, weekEnd, undefined, '[]'));
const getGroupedUserIds = (forecastings, weekStart, weekEnd) => _.groupBy(
  getFilteredForecasting(forecastings, weekStart, weekEnd), 'UserId',
);

const CollaboratorsList = (props) => {
  const {
    fullyStaffed,
    forecastings,
    ContactId,
    edit,
    projectId,
    setUpdateForecasting,
    updateForecasting,
    openModal,
  } = props;

  const location = useLocation();
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [open, setOpen] = useState(false);

  let colorPoint;
  let word;
  let weekStart = startDate.clone().startOf('isoWeek');
  let weekEnd = startDate.clone().endOf('isoWeek');
  let filteredForecasting = getFilteredForecasting(forecastings, weekStart, weekEnd);
  let groupedByUserId = getGroupedUserIds(forecastings, weekStart, weekEnd);

  useEffect(() => {
    weekStart = startDate.clone().startOf('isoWeek');
    weekEnd = startDate.clone().endOf('isoWeek');
    filteredForecasting = getFilteredForecasting(forecastings, weekStart, weekEnd);
    groupedByUserId = getGroupedUserIds(forecastings, weekStart, weekEnd);
  }, [startDate]);

  useEffect(() => {
    const userIds = Object.keys(groupedByUserId);

    if (userIds.length > 0) {
      api
        .get(`api/users?UsersIds=${userIds.join(',')}`)
        .then((res) => res.data.items)
        .then((data) => setUsers(data));
    }
  }, [forecastings, startDate]);

  useEffect(() => {
    if (openModal) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    openModal ? history.replace(location.state.openModal = false) : null;
  };

  const newusers = users.map((user) => {
    const userX = user;
    const weeklyIntensity = filteredForecasting.filter((f) => f.UserId === user.id)
      .reduce((acc, cur) => ({
        userId: cur.UserId,
        intensity: acc.intensity + cur.intensity / 5,
        roleInProject: cur.roleInProject,
        projectId: cur.ProjectId,
      }), { intensity: 0 });
    userX.forecast = weeklyIntensity;
    return userX;
  });

  if (fullyStaffed === '0') {
    colorPoint = 'rgba(218, 41, 28, 0.7)';
    word = 'Under staffed';
  } else if (fullyStaffed === '1') {
    colorPoint = '#FDB71A';
    word = 'Over Staffed';
  } else if (fullyStaffed === '2') {
    colorPoint = '#68D47E';
    word = 'Fully Staffed';
  }
  return (
    <div id="rightSection2">
      <>
        <div id="boxForecastingList">
          <div className="titleAndAddCollaborator">
            <h1>Collaborators</h1>
            <>
              <ButtonCustom
                isSimple
                event={() => setOpen(true)}
                text="Manage Collaborators"
                border="1px solid #001F33"
              />
              <ModalCustom
                handleClose={handleClose}
                open={open}
                width="676px"
                height="80vh"
                title="Manage Collaborators"
              >
                <BodyNewForecasting
                  updateForecasting={updateForecasting}
                  setUpdateForecasting={setUpdateForecasting}
                  projectId={projectId}
                  onClose={handleClose}
                  newUsers={newusers}
                />
              </ModalCustom>
            </>
          </div>
        </div>
      </>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '24px',
      }}
      >
        {edit ? (
          <Field
            type="text"
            name="fully_staffed"
            defaultValue={fullyStaffed}
            component={StaffedCustom}
          />
        ) : (
          <div id="projectStaffStatus">
            {word}
            <svg style={{ marginLeft: '8px' }} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6.5" r="6" fill={colorPoint} />
            </svg>
          </div>
        ) }

      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '24px',
      }}
      >
        <ForecastingDates setStartDate={setStartDate} start={weekStart} end={weekEnd} />
      </div>
      <div id="listCollaboratorsProject">
        {filteredForecasting.length === 0 && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '24px',
        }}
        >
          <Alert icon={false} severity="warning">There are no collaborators in this week</Alert>
        </div>
        )}
        {newusers && filteredForecasting.length > 0 ? newusers.map((coll) => (
          <CollaboratorProject coll={coll} contactPerson={ContactId} key={coll.id} />
        )) || <CircularProgress /> : ''}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
        <NavLink to={{ pathname: '/forecasting', state: { projectId } }} className="link">Go to Forecasting</NavLink>
      </div>
    </div>
  );
};

CollaboratorsList.defaultProps = {
  ContactId: undefined,
  fullyStaffed: '0',
  edit: false,
};

CollaboratorsList.propTypes = {
  ContactId: PropTypes.number,
  projectId: PropTypes.number.isRequired,
  fullyStaffed: PropTypes.string,
  edit: PropTypes.bool,
};

export default CollaboratorsList;
