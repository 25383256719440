import React from 'react';
import PropTypes from 'prop-types';
import { api } from '../../../api/api';
import ModalCustom from '../../../commonComponents/ModalCustom/ModalCustom';

import ConfirmationModal from '../../../dataComponents/ConfirmationModal/ConfirmationModal';

const DeleteNote = (props) => {
  const {
    noteId, open, setOpen, notes,
    setNotes,
  } = props;

  const handleDeleteNote = () => {
    api.delete(`api/comments/${noteId}`)
      .then(() => setOpen(false))
      .then(() => setNotes(notes.filter((note) => note.id !== noteId)));
  };

  return (

    <ModalCustom
      handleClose={() => setOpen(false)}
      open={open}
      width="676px"
      title="Delete the Note?"
    >
      <ConfirmationModal
        onArchiveOrDelete={() => handleDeleteNote()}
        onCancel={() => setOpen(false)}
      />
    </ModalCustom>
  );
};

DeleteNote.defaultProps = {
  notes: [],
};

DeleteNote.propTypes = {
  noteId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape()),
};

export default DeleteNote;
