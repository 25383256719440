import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  common: {
    fontSize: (props) => (props.variant === 'large' ? '14px' : '14px'),
    height: (props) => (props.variant === 'large' ? '48px !important' : '40px !important'),
    width: (props) => (props.variant === 'large' && '160px'),
    border: (props) => props.border,
    paddingLeft: '16px',
    paddingRight: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    textTransform: 'none',
    color: (props) => props.color,
    '&:focus': {
      backgroundColor: (props) => (props.color === '#DA291C' ? 'white' : 'rgba(0, 0, 0, 0.04)'),
    },
    '&:hover': {
      backgroundColor: (props) => (props.color === '#DA291C' ? 'white' : 'rgba(0, 0, 0, 0.04)'),
    },
  },
  button: {
    backgroundColor: '#001F33',
    color: 'white',
    borderRadius: '8px',
    '&:focus': {
      backgroundColor: 'rgb(255, 184, 28) !important',
      color: 'black',
    },
    '&:hover': {
      backgroundColor: 'rgb(255, 184, 28) !important',
      color: 'black',
    },
    '&.Mui-disabled': {
      backgroundColor: 'lightGrey',
      color: 'white',
    },
  },
}));

export default useStyles;
