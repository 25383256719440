import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import CreateWPContent from './CreateWPContent';
import ModalCustom from '../../../commonComponents/ModalCustom/ModalCustom';

import ButtonCustom from '../../../commonComponents/ButtonCustom/ButtonCustom';

const BudgetTimeAndMaterial = (props) => {
  const {
    workPakagesData, change, setChange, ProjectId, budgetType, edit,
  } = props;

  const [open, setOpen] = useState(false);

  let content;

  if (workPakagesData.lenght > 0) {
    content = <p>Hello la companie</p>;
  } else if (edit && !workPakagesData[0]) {
    content = (
      <div
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', height: '196px', flexDirection: 'column', border: '1px dashed rgba(0, 0, 0, 0.5)', borderRadius: '8px',
        }}
      >
        <ButtonCustom
          isSimple
          text="Forecast sprints"
          event={() => setOpen(true)}
          border="1px solid black"
          icon={<AddIcon />}
        />
        <ModalCustom
          handleClose={() => setOpen(false)}
          open={open}
          width="505px"
          title="Time and material plan"
        >
          <CreateWPContent
            ProjectId={ProjectId}
            setOpen={setOpen}
            change={change}
            setChange={setChange}
            budgetType={budgetType}
          />
        </ModalCustom>

      </div>
    );
  } else {
    content = <p />;
  }

  return edit && workPakagesData.lenght === 0 ? <p>blabla</p> : <p>{workPakagesData.lenght > 0 ? 'There is no Forecast created' : content}</p>;
};

BudgetTimeAndMaterial.defaultProps = {
  change: false,
  budgetType: undefined,
  workPakagesData: [],
};

BudgetTimeAndMaterial.propTypes = {
  workPakagesData: PropTypes.arrayOf(PropTypes.shape()),
  setChange: PropTypes.func.isRequired,
  change: PropTypes.bool,
  budgetType: PropTypes.string,
  ProjectId: PropTypes.number.isRequired,
  edit: PropTypes.bool.isRequired,

};

export default BudgetTimeAndMaterial;
