import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './style';

const ButtonCustom = (props) => {
  const {
    text, variant, event, border, icon, color, disabled, isSimple = false,
  } = props;
  const classes = useStyles({
    variant, border, color: color ?? isSimple ? 'black' : 'white',
  });

  return (
    <Button
      onClick={event}
      className={isSimple ? classes.common : `${classes.common} ${classes.button}`}
      startIcon={icon}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

ButtonCustom.defaultProps = {
  icon: <></>,
  border: 'none',
  event: () => {},
  color: undefined,
  disabled: false,
  variant: '',
  isSimple: false,
};

ButtonCustom.propTypes = {
  icon: PropTypes.element,
  border: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  event: PropTypes.func,
  disabled: PropTypes.bool,
  isSimple: PropTypes.bool,
};

export default ButtonCustom;
