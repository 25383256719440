/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import EuroIcon from '@material-ui/icons/Euro';
import EventIcon from '@material-ui/icons/Event';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import { mapUserRole } from '../../utils/mapUserRole';

const ForecastProjectNoEdit = (props) => {
  const groupedForecastings = useSelector((state) => state.forecastingsState.forecastingsByUserAndProjectId ?? []);
  const listForecasting = groupedForecastings
    .map((groupIntensity) => groupIntensity.map((sameDate) => {
      let word;
      if (sameDate[0].absence === 0) {
        word = 'No absence';
      } else if (sameDate[0].absence === 1) {
        word = 'Vacation';
      } else if (sameDate[0].absence === 2) {
        word = 'Sick';
      }
      return (

        <div key={sameDate[0].id} style={{ padding: '16px 0 16px 25px' }} id="forecastingModal">
          {sameDate[0].absence > 0 ? <p>{word}</p> : undefined}
          {sameDate[0].absence === 0 ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FlashOnIcon />
                <p style={{ marginLeft: '8px' }}>
                  {sameDate[0].intensity}
                  {' '}
                  %
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EuroIcon />
                <p style={{ marginLeft: '8px' }}>
                  {sameDate[0].hourlyRate}
                  {' '}
                  / hour
                </p>
              </div>
            </>
          ) : undefined}

          <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
            <EventIcon />
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
              <p>{moment(sameDate[0].startDate).format('MMM  D, YYYY')}</p>
              <TrendingFlatIcon style={{ marginLeft: '9px', marginRight: '9px' }} />
              {/* <p>{moment(sameDate[sameDate.length - 1].date).format('MMM  D, YYYY')}</p> */}
              <p>{moment(sameDate[0].endDate).format('MMM  D, YYYY')}</p>
            </div>
          </div>
          {sameDate[0].absence === 0 ? (
            <Chip style={{ marginTop: '8px' }} label={mapUserRole(sameDate[0].roleInProject) || 'No Role'} />
          ) : undefined}

          <hr style={{
            width: 'calc(100% - 25px)', marginLeft: 0, marginTop: '16px', border: '1px solid rgba(55, 58, 54, 0.1)',
          }}
          />

        </div>
      );
    }));

  return groupedForecastings.length === 0 ? (
    <p>
      Please note that you cant edit forecastings that are older than one month
    </p>
  ) : listForecasting;
};

export default ForecastProjectNoEdit;
