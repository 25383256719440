/* eslint-disable no-console */
import { api } from './api';

export default function ProjectsApi() {
  function fetchAll() {
    return api.get('api/projects')
      .then((response) => response.data.items)
      .catch((e) => console.error(e));
  }

  return ({
    fetchAll,
  });
}
