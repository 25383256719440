import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { api } from '../../api/api';
import ForecastProjectNoEdit from './ForecastProjectNoEdit';
import ForecastProjectEdit from './ForecastProjectEdit';
import { fetchForecastingsByUserAndProjectId } from '../../redux/ForecastingsSlice';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '346px',
    maxHeight: '450px',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  },
  rootButton: {
    backgroundColor: 'white',
    fontWeight: 400,
    color: 'rgba(112, 121, 135, 1)',
    fontSize: '14px',
    fontFamily: 'Roboto',
    textTransform: 'none',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#474747',
      outline: '2px solid white',
      borderRadius: '50px',
    },
  },
}));

const BodyModal = (props) => {
  const {
    idProject, idManager, idUser, setUpdateForecasting, onClose,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [project, setProject] = useState({});
  const [edit, setEdit] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [groupedForecastings, setGroupedForecastings] = useState([]);
  const [list, setList] = useState(false);

  useEffect(() => {
    api
      .get(`api/projects/${idProject}`)
      .then((res) => res.data.items)
      .then((data) => setProject(data));
  }, []);

  useEffect(() => {
    const params = {
      idManager,
      idUser,
      idProject,
      startDate: moment().subtract(1, 'months'),
      endDate: moment().add(2, 'years'),
    };

    dispatch(fetchForecastingsByUserAndProjectId(params));
  }, [list]);

  return edit ? (
    <ForecastProjectEdit
      groupedForecastings={groupedForecastings}
      idProject={idProject}
      idManager={idManager}
      idUser={idUser}
      project={project}
      onCancel={() => setEdit(false)}
      onSave={() => setEdit(false)}
      setUpdateForecasting={setUpdateForecasting}
      setList={setList}
      onClose={onClose}
    />
  ) : (
    <>
      <div id="modalHatEditNo">
        <div>
          <div style={{ backgroundColor: project?.color ?? 'black' }} />
          <p style={{ fontFamily: 'Roboto', fontWeight: 500 }}>{project?.project_name}</p>
        </div>
        <Button onClick={() => setEdit(true)} classes={{ root: classes.rootButton }}>
          Edit
        </Button>
      </div>
      <ForecastProjectNoEdit groupedForecastings={groupedForecastings} />
    </>
  );
};

BodyModal.defaultProps = {
  idManager: undefined,
};

BodyModal.propTypes = {
  idProject: PropTypes.number.isRequired,
  idUser: PropTypes.number.isRequired,
  idManager: PropTypes.number,
  setUpdateForecasting: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,

};

export default BodyModal;
