import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import ButtonCustom from '../../commonComponents/ButtonCustom/ButtonCustom';

const ConfirmationModal = (props) => {
  const {
    onArchiveOrDelete, onCancel, isArchive,
  } = props;
  return (
    <Box>
      <Box display="flex" gridGap="1.5rem" justifyContent="center">
        <ButtonCustom
          isSimple
          variant="large"
          text={isArchive ? 'Archive' : 'Delete'}
          border="1px solid #DA291C"
          event={onArchiveOrDelete}
          icon={isArchive ? <ArchiveIcon /> : <DeleteIcon />}
          color="#DA291C"
        />
        <ButtonCustom
          isSimple
          variant="large"
          text="Cancel"
          event={onCancel}
        />
      </Box>
    </Box>
  );
};

ConfirmationModal.defaultProps = {
  onArchiveOrDelete: () => undefined,
  onCancel: () => undefined,
  isArchive: false,
};

ConfirmationModal.propTypes = {
  onArchiveOrDelete: PropTypes.func,
  onCancel: PropTypes.func,
  isArchive: PropTypes.bool,
};

export default ConfirmationModal;
