import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import profileIcon from '../../ressources/profile-icon.png';

const NamePart = (props) => {
  const {
    firstName, lastName, position, profileImagePath,
  } = props;

  let fullname;
  if ((firstName + lastName).length > 17) {
    fullname = (`${firstName} ${lastName}`).substring(0, 17);
  } else {
    fullname = (`${firstName} ${lastName}`);
  }

  let positionSubStringed;
  if ((firstName + lastName).length > 18) {
    positionSubStringed = position.substring(0, 18);
  } else {
    positionSubStringed = position;
  }

  return (
    <div id="namePartCollaboratorBox">
      <Avatar
        title={`${firstName} ${lastName}`}
        alt={firstName + lastName}
        key={firstName + lastName}
        src={profileImagePath ? `${process.env.REACT_APP_BACK_URL}/api/users/profileImage/${profileImagePath}` : profileIcon}
      />
      <div>
        <p>
          {fullname}
        </p>
        <p>{positionSubStringed}</p>
      </div>
    </div>
  );
};

NamePart.defaultProps = {
  profileImagePath: undefined,
};

NamePart.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  profileImagePath: PropTypes.string,
};

export default NamePart;
