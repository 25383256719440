/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import IconButton from '@material-ui/core/IconButton';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import * as XLSX from 'xlsx';
import {
  Typography, Tooltip, Button, Paper,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import PriceWPEdit from './PriceWPEdit';
import ChartTimeBudget from './ChartTimeBudget';
import WorkPackageName from './WorkPackageName';
import { convertCurrency } from '../../../utils/convertCurrency';
import ModalCustom from '../../../commonComponents/ModalCustom/ModalCustom';

import ConfirmationModal from '../../../dataComponents/ConfirmationModal/ConfirmationModal';
import { api } from '../../../api/api';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  titleTotal: {
    marginTop: '16px',
    marginBottom: '16px',
    fontSize: '20px',
    fontWeight: 'bold',
    marginRight: '16px',
  },
  displayModeButton: {
    '&:hover': {
      background: 'none',
    },
    color: 'black',
    width: '32px',
    height: '32px',
    borderRadius: '8px',
  },
  rowBackground: {
    '&:hover': {
      backgroundColor: 'rgba(39, 130, 237, 0.05)',
    },
  },
  gridTable: {
    padding: '10px',
    boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    marginBottom: '20px',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableItem: {
    fontSize: '12px',
    lineHeight: '1rem',
    padding: '15px',
  },
  tableLargeHeader: {
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    paddingLeft: '10px',
    borderBottom: 'none',
  },
  tableCellhead: {
    height: '20px',
    paddingTop: '8px',
    paddingBottom: '8px',
    color: 'rgba(0, 0, 0, 0.5)',
    border: '1px solid #CCCCCC',
  },
  tableHeader: {
    maxHeight: '49px',
    lineHeight: '1rem',
  },
  tableRow: {
    '& td:nth-last-child(1)': {
      borderRight: '1px solid #CCCCCC',
    },
    '& td:first-child': {
      borderLeft: '1px solid #CCCCCC',
    },
  },
  tableCell: {
    border: '1px solid #CCCCCC',
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
  },
  tablePaper: {
    border: '1px solid #CCCCCC',
    borderRadius: '8px',
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '12px',
  },
}))(Tooltip);

const createData = (workPackageName, id, timeStamp,
  baseline, forecast, forecastSum, worked, workedSum, startingDate) => ({
  workPackageName, id, timeStamp, baseline, forecast, forecastSum, worked, workedSum, startingDate,
});

const DisplayModeButton = ({ active, children, ...props }) => (
  <IconButton {...props} style={{ backgroundColor: active ? 'rgba(0, 0, 0, 0.05)' : 'transparent' }}>
    {children}
  </IconButton>
);

const TableBudgetTotal = (props) => {
  const {
    data, budgetType, headers, tableType, edit, change, setChange, currency, convertCurrencyValue,
  } = props;

  const [isTable, setIsTable] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  const classes = useStyles({ isTable });

  const rows = data
    .map((item) => createData(item.workPackageName, item.id,
      item.timeStamp, item.baseline, item.forecast, 0, item.worked, 0, item.startingDate));

  const tableRef = React.createRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (tableRef.current) { tableRef.current.scrollTop = 0; }
    setPage(0);
  };

  function calculateAggForecast() {
    const stackedForecast = [];
    data.forEach((el, ind, arr) => stackedForecast.push(arr[ind - 1]
      ? el.forecast + stackedForecast[stackedForecast.length - 1] : el.forecast));
    return stackedForecast;
  }

  function calculateAggWorked() {
    const stackedWorked = [];
    data.forEach((el, ind, arr) => stackedWorked.push(arr[ind - 1]
      ? el.worked + stackedWorked[stackedWorked.length - 1] : el.worked));
    return stackedWorked;
  }
  const snapshotsIds = data.map((snapshot) => snapshot.id);

  const handleDeleteTable = () => {
    api.delete('api/snapshots?hardDelete=true', { data: snapshotsIds })
      .then(() => setChange(!change))
      .then(() => setOpenDelete(!openDelete));
  };

  const redefinedRows = () => {
    rows.map((row, index) => {
      const stackedForecast = calculateAggForecast();
      row.aggForecast = stackedForecast[index];

      const stackedWorked = calculateAggWorked();
      row.aggWorked = stackedWorked[index];
      let deviation = 0;
      if (tableType === 'Total-Workpackage') {
        deviation = stackedWorked[index] - data[index].baseline;
      } else {
        deviation = stackedWorked[index] - data[data.length - 1].baseline;
        row.startingDate = moment(row.startingDate).format('DD-MM-YYYY');
      }
      row.deviation = deviation > 0 ? `${deviation}d` : '0';
      const pOC = ((stackedWorked[index]
                    / stackedForecast[stackedForecast.length - 1]) * 100).toFixed(0);
      row.poc = (pOC > 0 && pOC !== 'Infinity') ? `${pOC}%` : '0%';
      return row;
    });
    return rows;
  };

  const handleAddSnapshot = (Project, workPackageName) => {
    const lastSprint = data[data.length - 1];
    api.post('api/snapshots', {
      projectId: Project,
      workPackageName,
      baseline: lastSprint.baseline,
      timeStamp: `S${parseInt(data[data.length - 1].timeStamp.slice(1), 10) + 1}`, // we increment the number after S
      budgetType,
      sprintLength: lastSprint.sprintLength,
      startingDate: lastSprint.startingDate,
    }).then(() => setChange(!change));
  };

  // colspan for the pagination is based on number of columns. the workpackage has more columns
  // so it will need a bigger colspan
  const colSpan = (type) => {
    switch (type) {
      case 'Total-Workpackage': {
        return 11;
      }
      case 'TimeAndMaterial': {
        return 7;
      }
      default: {
        return 13;
      }
    }
  };
  const getTableHeader = (type) => {
    switch (type) {
      case 'Total-Workpackage': {
        return 'Total WorkPackages';
      }
      case 'TimeAndMaterial': {
        return 'Sprint Forecast';
      }
      default: {
        return (
          <WorkPackageName
            edit={edit}
            workPackageName={data[0].workPackageName}
            snapshotsIds={snapshotsIds}
            change={change}
            setChange={setChange}
          />
        );
      }
    }
  };

  const exportToExcel = (type, filename) => {
    let filteredHeaders = headers;
    if (type === 'TimeAndMaterial') {
      filteredHeaders = headers.filter((header) => header.type === 'tm');
    } else if (type === 'WorkPackage') {
      filteredHeaders = headers.filter((header) => header.type !== 'global');
    }
    // eslint-disable-next-line max-len
    const ws = XLSX.utils.json_to_sheet(data, { header: filteredHeaders.map((header) => header.label) });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${getTableHeader(type, data, edit, snapshotsIds, change, setChange)} - ${filename}.xlsx`);
  };

  return (
    <>
      <TableContainer
        ref={tableRef}
        style={{ display: 'inline-block', overflowX: 'hidden' }}
        component={Paper}
      >
        <TableRow>
          <TableCell className={classes.tableLargeHeader} align="left" colSpan={tableType === 'TimeAndMaterial' ? 6 : 10}>
            {getTableHeader(tableType)}
            <DisplayModeButton
              active={isTable}
              className={classes.displayModeButton}
              onClick={() => setIsTable(true)}
            >
              <TableChartOutlinedIcon style={{ width: '20px', height: '20px' }} />
            </DisplayModeButton>
            <DisplayModeButton
              active={!isTable}
              className={classes.displayModeButton}
              onClick={() => setIsTable(false)}
            >
              <TrendingUpIcon style={{ width: '20px', height: '20px' }} />
            </DisplayModeButton>
            <Button
              className={classes.exportLink}
              onClick={() => exportToExcel(rows, headers, tableType === 'Total-Workpackage' || tableType === 'TimeAndMaterial' ? tableType : data[0].workPackageName)}
            >
              <DisplayModeButton className={classes.displayModeButton}>
                <GetAppIcon style={{ width: '20px', height: '20px' }} />
              </DisplayModeButton>
            </Button>
          </TableCell>
          {
                tableType !== 'Total-Workpackage' && (
                  <TableCell className={classes.tableLargeHeader} align="right" colSpan={1}>
                    <IconButton size="small" aria-label="expand row">
                      <LightTooltip title="Delete Table" placement="bottom-start">
                        <MoreHorizIcon onClick={() => setOpenDelete(true)} />
                      </LightTooltip>
                    </IconButton>
                  </TableCell>
                )
              }
          <ModalCustom
            handleClose={() => setOpenDelete(false)}
            open={openDelete}
            width="676px"
            title="Delete the table?"
          >
            <ConfirmationModal
              onArchiveOrDelete={() => handleDeleteTable()}
              onCancel={() => setOpenDelete(false)}
            />
          </ModalCustom>
        </TableRow>
        <Table className={classes.tablePaper} stickyHeader aria-label="sticky table">
          <TableHead>
            {isTable && (
              <TableRow style={{ verticalAlign: 'bottom' }}>
                {headers.map((column) => (
                  <TableCell
                    className={classes.tableCellHead}
                    key={column.key}
                    align={column.align}
                    colSpan={column.colSpan}
                    // style={{
                    // eslint-disable-next-line max-len
                    //   background: 'rgba(0, 0, 0, 0.03)', color: 'inherit', lineHeight: '1rem', width: column.width, outline: 0,
                    // }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          {isTable ? (
            <>
              <TableBody>
                {redefinedRows()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {headers.map((column) => {
                        const value = row[column.key];
                        return (
                          <TableCell
                            className={classes.tableCellHead}
                            key={column.key}
                            align={column.align}
                            colSpan={column.colSpan}
                            style={{ width: column.width }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                {tableType !== 'Total-Workpackage' && (
                  <TableRow>
                    <TableCell
                      colSpan={tableType === 'TimeAndMaterial' ? colSpan(tableType) - 1 : colSpan(tableType) - 3}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <Typography style={{ fontWeight: 'bold' }} variant="overline">Price</Typography>
                    </TableCell>
                    {edit
                      ? (
                        <PriceWPEdit
                          priceWP={data[0].totalPriceWP}
                          snapshotsIds={snapshotsIds}
                          change={change}
                          setChange={setChange}
                          colspan={tableType === 'TimeAndMaterial' ? colSpan(tableType) - 1 : colSpan(tableType) - 3}
                        />
                      )
                      : (
                        <TableCell colSpan={1} style={{ fontWeight: 'bold', borderRight: '1px solid rgba(224, 224, 224, 1)' }} component="th" scope="row" align="right">
                          {
                        convertCurrencyValue(data[0].totalPriceWP).toFixed(0)
                        }
                          {convertCurrency(currency)}
                        </TableCell>
                      )}
                  </TableRow>
                )}
                {edit && (
                  <TableRow>
                    <TableCell style={{ borderBottom: 'none', padding: '10px 0px 0px 0px' }}>
                      <Button
                        style={{ marginLeft: '10px', backgroundColor: 'rgba(55, 58, 54, 0.05)', textTransform: 'none' }}
                        onClick={() => handleAddSnapshot(data[0].projectId,
                          data[0].workPackageName)}
                      >
                        <Typography variant="outlined">
                          + Add
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell style={{ border: 'none' }} align="right" colSpan={11}>
                      {rows.length > 10 && (
                      <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableFooter>

            </>
          ) : <ChartTimeBudget data={data} budgetType={budgetType} />}

        </Table>
      </TableContainer>
    </>
  );
};

TableBudgetTotal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  budgetType: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableType: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
  change: PropTypes.bool.isRequired,
  setChange: PropTypes.func.isRequired,
};

DisplayModeButton.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  props: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default TableBudgetTotal;
