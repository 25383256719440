/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import SelectFormik from '../../commonComponents/SelectFormik/SelectFormik';
import DatePickerFormik from '../../commonComponents/DatePickerFormik/DatePickerFormik';
import LabelCustom from '../../commonComponents/LabelCustom/LabelCustom';

const Step4True = (props) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const {
    // edit,
    setValidation,
    shallNotPass,
  } = props;
  const [dateError, setDateError] = useState(false);
  const [dateMessage, setDateMessage] = useState(false);

  useEffect(() => {
    setValidation(true);
    setDateError(false);
  }, []);

  useEffect(() => {
    if (values.start_date === null) {
      setDateError(true);
      setValidation(true);
    } else if (typeof values.start_date !== 'undefined'
      && typeof values.end_date !== 'undefined'
      && moment(values.end_date).isAfter(values.start_date)) {
      // case in which the dates are correct
      setValidation(false);
      setDateError(false);
      setDateMessage(false);
    } else if (typeof values.start_date !== 'undefined'
      && typeof values.end_date !== 'undefined'
      && moment(values.end_date).isBefore(values.start_date)) {
      setDateError(false);
      setDateMessage(true);
      setValidation(true);
    } else {
      setDateError(false);
      setValidation(false);
    }
  }, [values.start_date, values.end_date, shallNotPass]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <LabelCustom label="Budget Type" />
          <SelectFormik
            name="time_budget_type"
            elements={['Not defined', 'Time and material', 'Work Package']
              .map((el) => ({ value: el, item: el }))}
            fullWidth
            size="small"
          />
        </Box>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <LabelCustom label="Start Date" isMandatory />
            <DatePickerFormik
              isMandatory
              title="Start Date"
              name="start_date"
              size="small"
              onChangeValue={(value) => {
                setFieldTouched('start_date', true);
                setFieldValue('start_date', value); // TODO: needed?
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <LabelCustom label="End Date" isMandatory />
            <DatePickerFormik
              name="end_date"
              size="small"
              onChangeValue={(value) => {
                setFieldTouched('end_date', true);
                setFieldValue('end_date', value); // TODO: needed?
              }}
              s
            />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <Typography variant="caption" className="errorMessageCreateCollaborator">
            {dateError && ('Start date is required.')}
            {dateMessage && ('End date must be later than Start date.')}
          </Typography>
        </Grid>
      </Grid>

    </Grid>
  );
};

Step4True.defaultProps = {
  // edit: false,
};

Step4True.propTypes = {
  // edit: PropTypes.bool,
  setValidation: PropTypes.func.isRequired,
  shallNotPass: PropTypes.shape().isRequired,
};

export default Step4True;
