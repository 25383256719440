/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Field } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconType from '../../IconType';
import TextFieldFormik from '../../../commonComponents/TextFieldFormik/TextFieldFormik';

const useStyles = makeStyles(() => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '185px',
    marginLeft: 0,
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    height: '40px',

  },
  selectSize: {
    height: '48px',
  },
  LabelType: {
    top: '-3px',
  },
}));

const ProjectTypeCustom = ({
  field, form: { touched, errors },
  projectT, ...props
}) => {
  const [projectType, setProjectType] = useState(projectT);

  const handleChangeProjectType = (event) => {
    setProjectType(event.target.value);
  };
  const classes = useStyles();

  return (
    <FormControl variant="outlined">
      <InputLabel classes={{ root: classes.LabelType }}>Project Type</InputLabel>
      <Select
        className={classes.selectSize}
        label="Project Type"
        name="project_type"
        // id="project_type"
        value={projectType}
        onChange={handleChangeProjectType}
        // displayEmpty
        defaultValue={projectT}
        style={{ height: 40 }}
        classes={{
          select: classes.select,
          outlined:
          classes.outlined,
          underline: classes.underline,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...field}
        {...props}
      >
        <MenuItem name="project_type" value="Not defined">
          <IconType type="Not defined" select="select" />
          Not defined
        </MenuItem>
        <MenuItem value="Development">
          <IconType type="Development" select="select" />
          Development
        </MenuItem>
        <MenuItem value="Design">
          <IconType type="Design" select="select" />
          Design
        </MenuItem>
        <MenuItem value="Cybersecurity">
          <IconType type="Cybersecurity" select="select" />
          Cybersecurity
        </MenuItem>
        <MenuItem value="Cloud">
          <IconType type="Cloud" select="select" />
          Cloud
        </MenuItem>
      </Select>
      {touched[field.name]
      && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </FormControl>
  );
};

const ProjectType = (props) => {
  const { projectType, budget, edit } = props;
  return (
    <>
      {edit
        ? (
          <Field
            // style={{ marginTop: '24px' }}
            type="text"
                        // id="project_name"
                          // value={values.project_name}
            name="project_type"
            placeholder="Your project type"
            //   className="fieldFormik"
            projectT={projectType}
            component={ProjectTypeCustom}
          />
        )

        : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconType type={projectType || 'Not defined'} />
            <p>{projectType || 'Project type not defined'}</p>
          </div>
        )}
    </>
  );
};

ProjectType.defaultProps = {
  projectType: undefined,
  budget: undefined,
};

ProjectType.propTypes = {
  edit: PropTypes.bool.isRequired,
  projectType: PropTypes.string,
  budget: PropTypes.string,
};

export default ProjectType;
