import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import { api } from '../../api/api';
import AnimationCreated from '../creationproject/AnimationCreated';
import ConfirmationModal from '../../dataComponents/ConfirmationModal/ConfirmationModal';
import ModalCustom from '../../commonComponents/ModalCustom/ModalCustom';
import ButtonCustom from '../../commonComponents/ButtonCustom/ButtonCustom';

const DeleteProject = (props) => {
  const { id, hardDeletion, project } = props;
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reasonArchived, setReasonArchived] = useState(false);

  const deleteProject = () => {
    if (hardDeletion) {
      api.delete(`api/projects/hardDelete/${id}`)
        .then(() => setSuccess(true))
        .then(() => setTimeout(() => {
          setSuccess(false);
          setOpen(false);
          history.push('/');
        }, 3000));
    } else {
      api.post(`api/projects/${id}`, { ...project, reasonArchived })
        .then(() => setSuccess(true))
        .then(() => setTimeout(() => {
          setSuccess(false);
          setOpen(false);
          history.push('/');
        }, 3000));
    }
  };
  return (
    <>
      {hardDeletion
        ? (
          <ButtonCustom
            variant="small"
            event={() => setOpen(true)}
            text="Delete the Project"
          />
        )
        : (
          <ButtonCustom
            isSimple
            variant="small"
            event={() => setOpen(true)}
            text="Archive project"
            icon={<ArchiveIcon />}
          />
        )}

      <ModalCustom
        handleClose={() => setOpen(false)}
        open={open}
        width="676px"
        title={hardDeletion ? 'Delete the project permanently.' : 'Archive project'}
      >
        {success
          ? (
            <AnimationCreated messageToDisplay={hardDeletion
              ? 'Project deleted' : 'Project archived'}
            />
          )
          : (
            <>
              {(
                !hardDeletion ? (
                  <TextField
                    variant="outlined"
                    style={{ marginBottom: '40px' }}
                    fullWidth
                    size="small"
                    placeholder="Please type the reason you want to archive the project."
                    onChange={(e) => setReasonArchived(e.target.value)}
                  />
                ) : undefined
            )}
              <ConfirmationModal
                onArchiveOrDelete={deleteProject}
                onCancel={() => setOpen(false)}
                isArchive={!hardDeletion}
              />
            </>
          )}
      </ModalCustom>
    </>
  );
};

DeleteProject.propTypes = {
  id: PropTypes.number.isRequired,
  hardDeletion: PropTypes.bool.isRequired,
  project: PropTypes.shape().isRequired,
};

export default DeleteProject;
