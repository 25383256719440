/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Field } from 'formik';
import { ThemeProvider } from '@material-ui/core/styles';
import TodayIcon from '@material-ui/icons/Today';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useStyles, customTheme } from './style';

const DatePickerCustom = ({
  field,
  isMandatory,
  onChangeValue,
  ...props
}) => {
  const classes = useStyles();
  const [invalidDateWarning, setInvalidDateWarning] = useState(false);
  const isValidDate = (selectedDate) => {
    const dateFormat = 'DD-MM-yyyy';
    return moment(selectedDate, dateFormat, true).isValid();
  };

  // eslint-disable-next-line no-console
  console.log(field);

  const handleChange = (selectedDate) => {
    const isValid = selectedDate ? isValidDate(selectedDate) : false;
    setInvalidDateWarning(selectedDate ? !isValid : false);

    const value = selectedDate && isValid ? selectedDate.toISOString() : null;
    onChangeValue(value);
  };

  return (
    <Box>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={customTheme}>
          <KeyboardDatePicker
            id={`dateCreation${field.name}Udpate`}
            {...field}
            {...props}
            placeholder="dd-mm-yyyy"
            autoOk
            fullWidth
            emptyLabel="dd-mm-yyyy"
            format="DD-MM-yyyy"
            size="small"
            inputVariant="outlined"
            InputAdornmentProps={{ position: 'start' }}
            onChange={handleChange}
            keyboardIcon={<TodayIcon className={classes.iconCalendar} />}
            className={`${invalidDateWarning ? classes.errorInput : ''} ${classes.smallerInput}`}
            inputLabelProps={{
              shrink: true,
              className: invalidDateWarning && isMandatory ? classes.errorLabel : '',
            }}
            error={invalidDateWarning && isMandatory}
            required={isMandatory}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      {invalidDateWarning && (
        <div className={classes.error}>
          {isMandatory ? 'You need a valid start date' : 'Date is invalid'}
        </div>
      )}
    </Box>
  );
};

const DatePickerFormik = (props) => (
  <Field
    component={DatePickerCustom}
    {...props}
  />
);

DatePickerCustom.defaultProps = {
  isMandatory: false,
  field: {
    name: null,
    value: null,
  },
};

DatePickerCustom.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  isMandatory: PropTypes.bool,
  onChangeValue: PropTypes.func.isRequired,
};

export default DatePickerFormik;
