import { React } from 'react';
import { Box, Grid } from '@material-ui/core';
import SelectFormik from '../../commonComponents/SelectFormik/SelectFormik';
import LabelCustom from '../../commonComponents/LabelCustom/LabelCustom';

const Step4False = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box>
        <LabelCustom label="Chance of Winning Project" />
        <SelectFormik
          name="chance_winning"
          elements={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            .map((el) => ({ value: el, item: `${el}%` }))}
          fullWidth
          size="small"
        />
      </Box>

    </Grid>
    <Grid item xs={12}>
      <Box>
        <LabelCustom label="Step in Sales Process" />
        <SelectFormik
          name="step_sale"
          elements={['Identify', 'Propose', 'Submission', 'Negotiating', 'Won', 'Lost', 'Cancelled']
            .map((el) => ({ value: el, item: el }))}
        />
      </Box>
    </Grid>

  </Grid>
);

export default Step4False;
