/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
import MomentUtils from '@date-io/moment';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PDFDownloadLink } from '@react-pdf/renderer';

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonCustom from '../../commonComponents/ButtonCustom/ButtonCustom';
import UserContext from '../../contexts/UserContext';
import ShowOnRoles from '../../utils/ShowOnRoles';
import Picture from './Picture';
import ProfilePdf from './ProfilePdf';

const useStyles = makeStyles(() => ({
  pdf: {
    height: '48px',
    backgroundColor: 'rgba(240, 240, 240, 1)',
    borderRadius: '8px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    textTransform: 'none',
    paddingLeft: '16px',
    paddingRight: '16px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#FFB81C',
      color: 'black',
      boxShadow: 'none',
      textDecoration: 'none',
    },
  },
  inputs: {
    marginBottom: '24px',
    width: '100%',
  },
  datetimecontainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '24px',
  },
  icon: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:active': {
      background: 'none',
    },
  },
}));

const GeneralInfosProfile = (props) => {
  const {
    edit, setEdit, profileImagePath, setProfileImagePath, setProfileImageForm,
    user, setUser, handleUpdate, skills, tools, technos, leadsNames,
  } = props;
  const classes = useStyles();
  const [showFirstNameHelperText, setShowFirstNameHelperText] = useState(false);
  const [showLastNameHelperText, setShowLastNameHelperText] = useState(false);
  const [showPositionHelperText, setShowPositionHelperText] = useState(false);

  const userLogged = useContext(UserContext);

  const userActivityProjects = useSelector((state) => state.activitiesState?.activities ?? []);
  const projectsNames = useSelector((state) => state.projectsState?.projects.reduce((acc, cur) => {
    if (acc.indexOf(cur.project_name) === -1) {
      acc.push({
        projectId: cur.id,
        projectName: cur.project_name,
      });
    }
    return acc;
  }, []));

  const activitiesList = useSelector((state) => state.activitiesListState?.activitiesList ?? []);

  const handleDisplayHourlyRateChange = (e) => {
    const { value } = e.target;
    setUser({ ...user, saleHourlyRate: value });
  };

  const handleFirstNameChange = (e) => {
    const { value } = e.target;
    setUser({ ...user, first_name: value });
    setShowFirstNameHelperText(value.length === 20);
  };

  const handleLastNameChange = (e) => {
    const { value } = e.target;
    setUser({ ...user, last_name: value });
    setShowLastNameHelperText(value.length === 20);
  };

  const handlePositionChange = (e) => {
    const { value } = e.target;
    setUser({ ...user, position: value });
    setShowPositionHelperText(value.length === 20);
  };

  return (
    <div>
      <div id="bluepart" />
      <div>
        <Picture
          edit={user.id === userLogged.id ? edit : false}
          profileImagePath={profileImagePath}
          setProfileImagePath={setProfileImagePath}
          setProfileImageForm={setProfileImageForm}
        />
        {edit ? (
          <div style={{
            marginTop: '14px', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '80%',
          }}
          >

            <TextField type="text" className={classes.inputs} inputLabelProps={{ shrink: true }} label="First Name" variant="outlined" value={user.first_name} onChange={handleFirstNameChange} inputProps={{ maxLength: 20 }} helperText={showFirstNameHelperText ? 'Maximum 20 characters reached' : ''} />
            <TextField type="text" className={classes.inputs} inputLabelProps={{ shrink: true }} label="Last Name" variant="outlined" value={user.last_name} onChange={handleLastNameChange} inputProps={{ maxLength: 20 }} helperText={showLastNameHelperText ? 'Maximum 20 characters reached' : ''} />
            <TextField type="text" className={classes.inputs} inputLabelProps={{ shrink: true }} label="Position" variant="outlined" value={user.position} onChange={handlePositionChange} inputProps={{ maxLength: 20 }} helperText={showPositionHelperText ? 'Maximum 20 characters reached' : ''} />
            <TextField type="email" className={classes.inputs} inputLabelProps={{ shrink: true }} label="Email" variant="outlined" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} disabled />
            <TextField type="text" className={classes.inputs} placeholder="Your computer" inputLabelProps={{ shrink: true }} label="Computer" variant="outlined" value={user.computer_used} onChange={(e) => setUser({ ...user, computer_used: e.target.value })} defaultValue="Not Defined" />
            <ShowOnRoles allowedRoleIds={[1, 2]}>
              <TextField type="text" className={classes.inputs} placeholder="Hourly Rate" inputLabelProps={{ shrink: true }} label="Hourly Rate" variant="outlined" value={user.saleHourlyRate} onChange={handleDisplayHourlyRateChange} inputProps={{ maxLength: 15 }} />
            </ShowOnRoles>

            <TextField
              inputLabelProps={{ shrink: true }}
              select
              className={classes.inputs}
              value={user.location}
              onChange={(e) => setUser({ ...user, location: e.target.value })}
              label="Location"
              variant="outlined"
              placeholder="hemmp"
            >
              <MenuItem value="Not Defined">
                <p>Not Defined</p>
              </MenuItem>
              <MenuItem value="Hamburg">Hamburg</MenuItem>
              <MenuItem value="Berlin">Berlin</MenuItem>
              <MenuItem value="Hannover">Hannover</MenuItem>
              <MenuItem value="Düsseldorf">Düsseldorf</MenuItem>
              <MenuItem value="Munich">Munich</MenuItem>
              <MenuItem value="Stuttgart">Stuttgart</MenuItem>
              <MenuItem value="Francfort">Francfort</MenuItem>
            </TextField>

            <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
              <TextField
                inputLabelProps={{ shrink: true }}
                select
                className={classes.inputs}
                value={user.roleId}
                onChange={(e) => setUser({ ...user, roleId: e.target.value })}
                label="Role"
                variant="outlined"
              >
                <MenuItem value={1}>
                  Director
                </MenuItem>
                <MenuItem value={2}>Manager</MenuItem>
                <MenuItem value={3}>Collaborator</MenuItem>
              </TextField>
              <TextField
                inputLabelProps={{ shrink: true }}
                select
                className={classes.inputs}
                value={typeof user.leadId === 'undefined' ? false : user.leadId}
                onChange={(e) => setUser({ ...user, leadId: e.target.value })}
                label="Team Lead"
                variant="outlined"
              >
                <MenuItem value={false}>Not Defined</MenuItem>
                {
                  leadsNames && leadsNames
                    .map((lead) => <MenuItem value={lead.id}>{lead.name}</MenuItem>)
                }
              </TextField>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.inputs}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        autoOk
                        format="DD/MM/YYYY"
                        placeholder="jj/mm/aaaa"
                        variant="dialog"
                        disableFuture
                        inputVariant="outlined"
                        value={user.startedAt ? user.startedAt : null}
                        InputAdornmentProps={{ position: 'start' }}
                        onChange={(e) => setUser({ ...user, startedAt: e })}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
              </Grid>
            </ShowOnRoles>

          </div>
        ) : (
          <div style={{
            marginTop: '14px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '80%',
          }}
          >
            <p className="username">{`${user.first_name}  ${user.last_name}`}</p>
            <p style={{ marginTop: '8px' }}>{user.position}</p>
            <p>{user.email}</p>
            <div id="locationProfilePage">
              <LocationOnIcon id="iconLocation" />
              <span>{user.location}</span>
            </div>
            <p>
              My computer:
              {' '}
              {
                user.computer_used
                  ? user.computer_used : 'Not Defined'
              }

            </p>
            <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
              <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '4px',
              }}
              >
                <strong>
                  Hourly Rate:
                  {' '}
                  {user.saleHourlyRate}
                </strong>
              </div>
              <p>
                Role:
                {' '}
                {user.roleId === 1 ? 'Director' : user.roleId === 2 ? 'Manager' : 'Collaborator'}
              </p>
            </ShowOnRoles>
            <Typography>
              Team Lead:
              {' '}
              {user.TeamLead ? `${user.TeamLead.first_name} ${user.TeamLead.last_name}` : 'Not Defined' }
            </Typography>
            <Typography>
              {' '}
              Collaborator since :
              {user.startedAt ? ` ${moment(user.startedAt).format('L')}` : ' Not defined'}
            </Typography>
          </div>

        )}

        <div id="boxButton">
          {!edit && (
          <PDFDownloadLink
            document={(
              <ProfilePdf
                user={user}
                skills={skills}
                technos={technos}
                tools={tools}
                userActivityProjects={userActivityProjects}
                projectsNames={projectsNames}
                activitiesList={activitiesList}
              />
            )}
            fileName={`${user.first_name}  ${user.last_name}`}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" className={classes.pdf}>Export as PDF</Button>
          </PDFDownloadLink>
          )}
          {(userLogged.roleId === 3 && userLogged?.id === user?.id) || userLogged?.roleId !== 3
            ? (<ButtonCustom variant="large" text={edit ? 'Save' : 'Edit profile'} event={edit ? handleUpdate : () => setEdit(true)} />) : undefined }
          {edit && (<ButtonCustom isSimple variant="large" text="Cancel" event={() => setEdit(false)} />)}
        </div>
      </div>
    </div>
  );
};

GeneralInfosProfile.defaultProps = {
  handleUpdate: () => {},
  technos: [],
  skills: [],
  tools: [],
  startedAt: null,
};

GeneralInfosProfile.propTypes = {
  profileImagePath: PropTypes.string.isRequired,
  setProfileImagePath: PropTypes.func.isRequired,
  setProfileImageForm: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func,
  user: PropTypes.shape().isRequired,
  startedAt: PropTypes.instanceOf(Date),
  leadsNames: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  technos: PropTypes.arrayOf(PropTypes.shape()),
  skills: PropTypes.arrayOf(PropTypes.shape()),
  tools: PropTypes.arrayOf(PropTypes.shape()),
};

export default GeneralInfosProfile;
