import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import NamePart from './NamePart';
import SkillsToolsTechnos from './SkillsToolsTechnos';
import TotalIntensity from '../TotalIntensity';
import ProjectCurrent from './ProjectCurrent';
import LeadNamePart from './LeadNamePart';

const Collaborator = (props) => {
  const {
    coll, forecasting, id, totalIntensityByUser,
  } = props;

  return (
    <div id="collaboratorGlobal">
      <NamePart
        firstName={coll.first_name}
        lastName={coll.last_name}
        position={coll.position}
        profileImagePath={coll.profileImagePath}
      />
      <Box width="calc(10.16% - 22px)" paddingRight="22px" display="flex" justifyContent="flex-start" textOverflow="clip">
        <p id="collLocation">{coll.location}</p>
      </Box>
      <SkillsToolsTechnos Skills={coll.Skills} Technos={coll.Technos} Tools={coll.Tools} />
      <TotalIntensity coll={coll} totalIntensityByUser={totalIntensityByUser} forecasting={forecasting} id={id} totalintensitydisplayed="no" />
      <ProjectCurrent coll={coll} forecasting={forecasting} id={id} />
      <LeadNamePart
        leadName={coll.TeamLead && `${coll.TeamLead.first_name} ${coll.TeamLead.last_name}`}
      />
    </div>
  );
};

Collaborator.propTypes = {
  totalIntensityByUser: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.number,
    intensity: PropTypes.number,
  })).isRequired,
  id: PropTypes.number.isRequired,
  forecasting: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.number,
    roleInProject: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    intensity: PropTypes.number,
    date: PropTypes.string,
    projectName: PropTypes.string,
    projectId: PropTypes.number,
    end_date: PropTypes.string,
    start_date: PropTypes.string,
    status: PropTypes.string,
  })).isRequired,
  coll: PropTypes.shape({
    TeamLead: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    profileImagePath: PropTypes.string,
    position: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    Skills: PropTypes.arrayOf(PropTypes.shape({
      skill_name: PropTypes.string,
    })),
    Tools: PropTypes.arrayOf(PropTypes.shape({
      tool_name: PropTypes.string,
    })),
    Technos: PropTypes.arrayOf(PropTypes.shape({
      techno_name: PropTypes.string,
    })),
  }).isRequired,
};

export default Collaborator;
