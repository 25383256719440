import React from 'react';
import {
  Box, IconButton, Modal, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';

const ModalCustom = (props) => {
  const {
    height, title, width, children, open, handleClose,
  } = props;

  const classes = useStyles({ heightModal: height, widthModal: width });

  return (
    <Modal
      className={classes.modal}
      open={open}
    >
      <Box className={classes.paper}>
        <Box style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '95%',
        }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
            <Typography />
            <Typography className={classes.title}>{title}</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon classes={{ root: classes.iconButtonClear }} />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

ModalCustom.defaultProps = {
  height: 'auto',
  open: false,
  handleClose: undefined,
  width: '676px',
  title: 'Create the project',
  children: <Typography>No content, sorry</Typography>,
};

ModalCustom.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  height: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.element,
};

export default ModalCustom;
