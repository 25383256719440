import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { api } from '../../api/api';
import AutoCompleteFormik from '../../commonComponents/AutoCompleteFormik/AutoCompleteFormik';
import useStyles from './style';

const ManagerAutocomplete = ({ isCreation }) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const [managersList, setManagersList] = useState([]);

  useEffect(() => {
    api
      .get('api/users?RolesIds=1,2')
      .then((res) => {
        setManagersList(res.data.items);
      });
  }, []);

  return (
    <AutoCompleteFormik
      options={managersList}
      name="Manager"
      getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
      disableClearable
      placeholder="Select a manager"
      value={values.Manager}
      onChange={(_, v) => setFieldValue('Manager', v.value ?? v)}
      id={isCreation ? 'autoCompleteCreateProject' : ''}
      shrink
      inputLabelProps={{ shrink: !isCreation }}
      style={{ width: '100%' }}
      classes={{
        inputFocused: classes.inputFocused,
        inputRoot: isCreation ? classes.inputRoot : '',
      }}
    />
  );
};

ManagerAutocomplete.defaultProps = {
  isCreation: false,
};

ManagerAutocomplete.propTypes = {
  isCreation: PropTypes.bool,
};

export default ManagerAutocomplete;
