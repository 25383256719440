import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  pointFilter: {
    width: '18px',
    height: '18px',
    marginRight: '10px',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '170px',
    padding: '9.5px 0px 9.5px 16px',
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    padding: '10.5px 0 10.5px 0px',
    height: '40px',
  },
}));

export default useStyles;
