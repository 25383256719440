import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  inputFocused: {
    marginLeft: '16px',
  },
  inputRoot: {
    height: '40px',
  },
}));

export default useStyles;
