import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { api } from '../../api/api';
import AutoCompleteFormik from '../../commonComponents/AutoCompleteFormik/AutoCompleteFormik';
import useStyles from './style';

const DeliveryLeadAutocomplete = ({ isCreation }) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const [deliveryLeadsList, setdeliveryLeadssList] = useState([]);

  useEffect(() => {
    api
      .get('api/users?RolesIds=1,2,3')
      .then((res) => {
        setdeliveryLeadssList(res.data.items);
      });
  }, []);

  return (
    <AutoCompleteFormik
      options={deliveryLeadsList}
      name="DeliveryLead"
      getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
      disableClearable
      placeholder="Select a delivery lead"
      value={values.DeliveryLead}
      onChange={(_, v) => setFieldValue('DeliveryLead', v.value ?? v)}
      id={isCreation ? 'autoCompleteCreateProject' : ''}
      shrink
      inputLabelProps={{ shrink: !isCreation }}
      style={{ width: '100%' }}
      classes={{
        inputFocused: classes.inputFocused,
        inputRoot: isCreation ? classes.inputRoot : '',
      }}
    />
  );
};

DeliveryLeadAutocomplete.defaultProps = {
  isCreation: false,
};

DeliveryLeadAutocomplete.propTypes = {
  isCreation: PropTypes.bool,
};

export default DeliveryLeadAutocomplete;
