import { makeStyles, createTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dateLabel: {
    display: 'flex',
    gap: '4px',
  },
  error: {
    marginTop: '4px',
    color: 'red',
    fontSize: '0.8rem',
  },
  errorInput: {
    borderColor: 'red !important',
  },
  errorLabel: {
    color: 'red !important',
  },
  smallerInput: {
    '& .MuiOutlinedInput-input': {
      padding: '1px 8px',
      fontSize: '2rem',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(1px, 8px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(1px, -6px) scale(0.75)',
    },
  },
}));

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#FFB81C',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$error': {
          '& $notchedOutline': {
            borderColor: 'red',
          },
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#FFB81C',
        },
        '&$focused $notchedOutline': {
          borderColor: '#FFB81C',
        },
      },
      notchedOutline: {
        borderRadius: '8px',
      },
      input: {
        padding: '1px 8px',
        fontSize: '1rem',
      },
    },
    MuiInputLabel: {
      outlined: {
        '&$focused': {
          color: '#FFB81C',
        },
      },
    },
  },
});

export { useStyles, customTheme };
