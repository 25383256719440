/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Button,
  Card,
  Divider,
  InputAdornment,
  OutlinedInput,
  TextField, TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { api } from '../../../api/api';
import ButtonCustom from '../../../commonComponents/ButtonCustom/ButtonCustom';
import UserContext from '../../../contexts/UserContext';

const NotesForm = ({
  ProjectId, setSearchTerm, setTrigger, setOpen, open, useStyles, reload, setReload, numberNotes,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [isValidComment, setIsValidComment] = useState(true);
  const [isValidTitle, setIsValidTitle] = useState(true);
  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const user = useContext(UserContext);

  const handleChangeTitle = (e) => {
    setHasBeenTouched(true);
    const newTitle = e.target.value;
    setTitle(newTitle.length > 20 ? newTitle.slice(0, 20) : e.target.value);
    if (newTitle.length === 0 || newTitle.trim().length === 0 || newTitle.length < 2) {
      setIsValidTitle(false);
    } else {
      setIsValidTitle(true);
    }
  };
  const handleChangeComment = (e) => {
    setHasBeenTouched(true);
    const newComment = e.target.value;
    setComment(newComment);
    if (newComment.length === 0 || newComment.trim().length === 0 || newComment.length > 5000) {
      setIsValidComment(false);
      setHasBeenTouched(false);
    } else {
      setIsValidComment(true);
      setHasBeenTouched(false);
    }
  };

  const handleSubmit = () => () => {
    if (!hasBeenTouched) {
      setHasBeenTouched(true);
      if (title.length === 0 || title.trim().length === 0) {
        setIsValidTitle(false);
      }
      if (comment.length === 0 || comment.trim().length === 0) {
        setIsValidComment(false);
      }
    }
    if (isValidTitle && isValidComment) {
      api({
        method: 'post',
        url: 'api/comments',
        data: {
          title, comment, ProjectId, UserId: user.id || 3,
        },
      }).then(() => {
        setTrigger(true);
        setOpen(false);
        setReload(!reload);
      })
        .then(() => { setTitle(''); setComment(''); })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('Error when trying to post a new note (comment): ', err);
        });
    }
  };

  const handleClose = () => {
    setTitle('');
    setComment('');
    setIsValidTitle(true);
    setIsValidComment(true);
    setHasBeenTouched(false);
    setOpen(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginTop="-16px">
        <OutlinedInput
          id="outlined-adornment-amount"
          type="search"
          startAdornment={<InputAdornment style={{ marginLeft: '10px' }} position="start"><Search /></InputAdornment>}
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ height: '40px', width: '30vw' }}
        />

        <Box display="flex" alignItems="center" gridGap="24px">
          <Typography style={{ color: 'rgba(0, 0, 0, 0.5)', fontWeight: 400 }}>
            {numberNotes}
            {' '}
            Notes
          </Typography>
          <Button
            onClick={() => setOpen(true)}
            style={{
              textTransform: 'capitalize',
              backgroundColor: 'rgba(55, 58, 54, 0.05)',
              marginRight: '20px',
              float: 'right',
            }}
          >
            + Add
          </Button>
        </Box>

      </Box>
      <Divider style={{
        marginLeft: '-32px', marginRight: '-32px', marginTop: '24px', marginBottom: '24px',
      }}
      />

      <Card hidden={!open} className={classes.noteBox} elevation={0} style={{ marginBottom: '24px' }}>
        <TextField
          onChange={handleChangeTitle}
          placeholder="Title"
          className={classes.titleText}
          fullWidth
          value={title}
          style={{ marginBottom: '10px', marginLeft: '2px' }}
        />
        <TextareaAutosize
          onChange={handleChangeComment}
          value={comment}
          minRows={2}
          placeholder="Write a comment ..."
          className={classes.descriptionProject}
        />
        {(hasBeenTouched && !isValidTitle) ? (<p style={{ color: 'rgba(218, 41, 28, 0.7)', marginBottom: '10px' }}>The title is mandatory with 2 characters minimum.</p>) : ''}
        {(hasBeenTouched && !isValidComment) ? <p style={{ color: 'rgba(218, 41, 28, 0.7)', marginBottom: '10px' }}>A description of the note is mandatory with 2 to 5000 chars minimum.</p> : ''}

        <Box display="flex" justifyContent="flex-end" gridGap="10px">
          <ButtonCustom isSimple variant="small" text="Cancel" event={handleClose} />
          <ButtonCustom variant="small" text="Save" event={handleSubmit()} />
        </Box>

      </Card>
    </>
  );
};

NotesForm.defaultProps = {
  open: false,
  reload: false,
  numberNotes: 0,
};

NotesForm.propTypes = {
  ProjectId: PropTypes.number.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setTrigger: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  useStyles: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  numberNotes: PropTypes.number,
  open: PropTypes.bool,
  reload: PropTypes.bool,
};

export default NotesForm;
