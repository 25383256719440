/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LoopIcon from '@material-ui/icons/Loop';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Slider, makeStyles } from '@material-ui/core';
import CustomizedProgressBars from '../Progression';
import SelectFormik from '../../commonComponents/SelectFormik/SelectFormik';

const useStyles = makeStyles(() => ({
  pointFilter: {
    width: '11px',
    height: '11px',
    marginRight: '8px',
    color: 'red',
  },
}));

const ProjectStatus = (props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    status, startDate, endDate, progression, edit, values, setFieldValue, chanceWinning, stepSale,
  } = props;

  const classes = useStyles();
  const todayDate = moment(Date.now());
  const onGoing = moment(startDate).isBefore(todayDate)
    && (!endDate || moment(todayDate).isBefore(endDate));
  const finished = (moment(endDate).isBefore(todayDate));
  const name = onGoing ? 'progression' : 'chance_winning';

  let colorBar;
  if (finished) {
    colorBar = 'grey';
  } else if (status === 'green' && onGoing) {
    colorBar = 'rgba(45, 200, 77, 0.7)';
  } else if (status === 'orange' && onGoing) {
    colorBar = 'rgba(253, 183, 26, 1)';
  } else if (status === 'red' && onGoing) {
    colorBar = 'rgba(218, 41, 28, 0.7)';
  } else if (!status && onGoing) {
    colorBar = 'grey';
  } else if (finished) {
    colorBar = 'grey';
  } else {
    colorBar = '#518DEE';
  }

  let projectStatusOGFP;
  if (onGoing) {
    projectStatusOGFP = 'On Going';
  } else if (finished) {
    projectStatusOGFP = 'Finished';
  } else {
    projectStatusOGFP = 'Future';
  }

  const StatusIcon = ({ color }) => (
    <span style={{
      height: '12px',
      width: '12px',
      backgroundColor: color,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '12px',
    }}
    />
  );

  return (
    <div id="rightSection1" className="sections">
      <h1>Project Status</h1>
      {edit && !finished
        ? (
          <div>
            <div style={{
              display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '16px',
            }}
            >
              <Slider
                style={{ color: colorBar, width: '95%' }}
                name={name}
                value={values[name] ?? (onGoing ? progression : chanceWinning)}
                onChange={(e, v) => setFieldValue(name, v)}
                colorBar={colorBar}
                defaultValue={onGoing ? progression : chanceWinning}
                {...props}
              />
              <p>
                {onGoing ? values.progression : values.chance_winning}
                %
              </p>
            </div>
            <div style={{ marginBottom: '32px' }}>
              {onGoing ? (
                <SelectFormik
                  elements={[
                    {
                      value: 'green',
                      item:
                  <>
                    <StatusIcon color="#68D47E" />
                    Green
                  </>,
                    },
                    {
                      value: 'orange',
                      item:
                  <>
                    <StatusIcon color="#FDB71A" />
                    Orange
                  </>,
                    },
                    {
                      value: 'red',
                      item:
                  <>
                    <StatusIcon color="rgba(218, 41, 28, 0.7)" />
                    Red
                  </>,
                    },
                  ]}
                  name="status"
                  defaultValue={values.status}
                />
              ) : (
                <SelectFormik
                  elements={[{ value: 'Identify', item: 'Identify' }, { value: 'Propose', item: 'Propose' }, { value: 'Submission', item: 'Submission' }, { value: 'Negotiating', item: 'Negotiating' }, { value: 'Won', item: 'Won' }, { value: 'Lost', item: 'Lost' }, { value: 'Canceled', item: 'Canceled' }]}
                  name="step_sale"
                  defaultValue={stepSale}
                />
              )}
            </div>
          </div>
        )
        : ( // We work here with three time scales
          <>
            <div id="progressionStyle">
              <CustomizedProgressBars
                evolution={finished ? 100 : onGoing ? progression : chanceWinning}
                colorBar={colorBar}
              />
              <p style={{ marginTop: '9px' }}>
                {finished ? 100 : onGoing ? progression : chanceWinning || '--'}
                %
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '0 0 24px 0' }}>
              <LoopIcon color="primary" />
              <p style={{ marginLeft: '12px' }}>{projectStatusOGFP}</p>
              {!onGoing && !finished ? (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}>
                  <AttachMoneyIcon style={{ color: '#85bb65', marginRight: '9px' }} />
                  <p>{values.step_sale}</p>
                </div>
              ) : null}

            </div>
          </>
        )}

    </div>
  );
};

ProjectStatus.propTypes = {
  status: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  progression: PropTypes.number,
  chanceWinning: PropTypes.number,
  edit: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    progression: PropTypes.number,
  }).isRequired,
};

ProjectStatus.defaultProps = {
  status: 'orange',
  startDate: undefined,
  endDate: undefined,
  progression: undefined,
  edit: false,
  chanceWinning: 0,
};

export default ProjectStatus;
