import React from 'react';
import PropTypes from 'prop-types';
import { api } from '../../../../api/api';
import ConfirmationModal from '../../../../dataComponents/ConfirmationModal/ConfirmationModal';
import ModalCustom from '../../../../commonComponents/ModalCustom/ModalCustom';

const DeleteCapex = (props) => {
  const {
    capexId, updateExpenses, setUpdateExpenses, open, setOpen,
  } = props;

  const handleDeleteExpense = (id) => {
    api.delete(`api/expenses/${id}`)
      .then(() => setUpdateExpenses(!updateExpenses))
      .then(() => setOpen(false));
  };

  return (
    <ModalCustom
      handleClose={() => setOpen(false)}
      open={open}
      width="676px"
      title="Delete the Capex?"
    >
      <ConfirmationModal
        onArchiveOrDelete={() => handleDeleteExpense(capexId)}
        onCancel={() => setOpen(false)}
      />
    </ModalCustom>
  );
};

DeleteCapex.propTypes = {
  updateExpenses: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setUpdateExpenses: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  capexId: PropTypes.number.isRequired,
};

export default DeleteCapex;
