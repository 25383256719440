/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  labelIntensity: {
    fontWeight: 700,
    fontSize: '14px',
    color: (props) => props.colorChangeLabel,
  },
  colorIntensity: {
    strokeLinecap: 'round',
    color: (props) => props.colorChangeIntensity,
  },
});

const CircularProgressWithLabel = (props) => {
  const { totalintensity } = props;
  let colorChangeLabel;

  if (totalintensity <= 70 || totalintensity >= 130) {
    colorChangeLabel = 'rgba(246, 88, 107, 1)';
  } else if (totalintensity < 100 && totalintensity > 80) {
    colorChangeLabel = 'rgba(55, 58, 54, 1)';
  } else {
    colorChangeLabel = 'rgba(55, 58, 54, 1)';
  }

  const classes = useStyles({ colorChangeLabel });
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.labelIntensity} variant="caption" component="div" color="textSecondary">
          {`${Math.round(
            props.totalintensity,
          )}%`}

        </Typography>
      </Box>
    </Box>
  );
};

const TotalIntensity = (props) => {
  // const dispatch = useDispatch();
  const {
    coll, totalintensitydisplayed, totalIntensityByUser, id,
  } = props;

  function getTotalIntensity() {
    let totalIntensity;
    let totalIntensityByUserArr;
    if (totalIntensityByUser && totalIntensityByUser.length > 0) {
      totalIntensityByUserArr = totalIntensityByUser.filter((user) => user.userId === id);
      if (totalIntensityByUserArr.length > 0) {
        totalIntensity = totalIntensityByUserArr[0].intensity;
      }
    }
    return totalIntensity;
  }
  let colorChangeIntensity;
  const totalIntensity = coll.forecast ? coll.forecast.intensity : getTotalIntensity();
  if (totalIntensity <= 70 || totalIntensity >= 130) {
    colorChangeIntensity = 'rgba(247, 88, 107, 0.85)';
  } else if (totalIntensity >= 90 && totalIntensity <= 110) {
    colorChangeIntensity = 'rgb(45, 200, 77)';
  } else {
    colorChangeIntensity = '#FFB81C';
  }

  const classes = useStyles({ colorChangeIntensity });

  const renderTotalIntensity = () => (
    <div id={totalintensitydisplayed === 'no' ? 'listCollIntensity' : ''}>
      {totalIntensityByUser && totalIntensityByUser.filter((user) => user.userId === id).map((user) => (
        <CircularProgressWithLabel
          className={classes.colorIntensity}
          value={user.intensity > 100 ? 100 : user.intensity}
          thickness={5}
          size="55px"
          classes={{ svg: classes.colorIntensity }}
          totalintensity={user.intensity}
          key={`CircularProgressWithLabel_${user.userId}`}
          totalintensitydisplayed={totalintensitydisplayed}
        />
      )) }
    </div>
  );

  return (
    <div id={totalintensitydisplayed === 'no' ? 'listCollIntensity' : ''}>
      {totalintensitydisplayed === 'no' ? renderTotalIntensity()
        : (
          <CircularProgressWithLabel
            className={classes.colorIntensity}
            value={coll.forecast?.intensity > 100 ? 100 : coll.forecast.intensity}
            thickness={5}
            size="55px"
            classes={{ svg: classes.colorIntensity }}
            totalintensity={coll.forecast?.intensity}
            totalintensitydisplayed={totalintensitydisplayed}
          />
        )}
    </div>
  );
};

CircularProgressWithLabel.defaultProps = {
  totalintensity: 0,
  value: 0,
};
CircularProgressWithLabel.propTypes = {
  totalintensity: PropTypes.number,
  totalintensitydisplayed: PropTypes.string.isRequired,
  value: PropTypes.number,
};

TotalIntensity.propTypes = {
  id: PropTypes.number.isRequired,
  totalintensitydisplayed: PropTypes.string.isRequired,
  coll: PropTypes.shape({
    id: PropTypes.number.isRequired,
    forecast: PropTypes.shape({
      intensity: PropTypes.number,
    }),
  }).isRequired,
  totalIntensityByUser: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.number,
    intensity: PropTypes.number,
  })).isRequired,
};

export default TotalIntensity;
