/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Box, Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Field, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ButtonCustom from '../../commonComponents/ButtonCustom/ButtonCustom';

const ProjectLink = (props) => {
  const {
    index, edit, errors,
  } = props;
  const { setFieldValue, values } = useFormikContext();

  const handleDelete = () => {
    setFieldValue('Links', values.Links.filter((_, idx) => idx !== index));
  };

  const updateLinkTitle = (event) => {
    setFieldValue(`Links.${index}.title`, event.target.value);
  };

  const updateLinkUrl = (event) => {
    setFieldValue(`Links.${index}.url`, event.target.value);
  };

  const fieldTitle = (
    <>
      <TextField
        id="inputTitle"
        fullWidth
        name={`Links.${index}.title`}
        value={values.Links[index].title}
        variant="outlined"
        placeholder="Description"
        className={edit ? '' : 'titleLinkProject'}
        onChange={updateLinkTitle}
      />
      <Typography variant="caption" className="errorMessageCreateCollaborator" style={{ display: 'block', blockSize: '0px', marginBottom: '20px' }}>{ errors.length > 0 && errors[index].title}</Typography>
    </>
  );

  const fieldUrl = (
    <>
      <TextField
        id="inputUrl"
        fullWidth
        name={`Links.${index}.url`}
        value={values.Links[index].url}
        variant="outlined"
        placeholder="URL (e.g.. https://www.akkodis.com)"
        className={edit ? '' : 'urlLinkProject'}
        onChange={updateLinkUrl}
      />
      <Typography variant="caption" className="errorMessageCreateCollaborator" style={{ display: 'block', blockSize: '0px', marginBottom: '20px' }}>{errors.length > 0 && errors[index].link}</Typography>
    </>
  );

  return (
    <div className={edit ? 'componentLinkUpdate' : 'componentLink'}>
      {edit ? (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography className="title" variant="caption">Description: </Typography>
          </Grid>
          <Grid item xs={4}>
            {fieldTitle}
          </Grid>
          <Grid item xs={1}>
            <Typography className="title" variant="caption">URL: </Typography>
          </Grid>
          <Grid item xs={5}>
            {fieldUrl}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            {fieldTitle}
          </Grid>
          <Grid item xs={8}>
            {fieldUrl}
          </Grid>
        </Grid>
      ) }
      <Field style={{ display: 'none' }} name={`Links.${index}.ProjectId`} />
      <IconButton aria-label="delete" onClick={handleDelete}>
        <ClearIcon />
      </IconButton>
    </div>
  );
};

ProjectLink.propTypes = {
  edit: PropTypes.bool.isRequired,
};

/**
 * This component ONLY works
 * if inside a Formik form.
 */
const LinksCreator = ({
  initWithOneEmpty, edit, setValidation, shallNotPass,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const links = values.Links;
  const ProjectId = values.id;
  // eslint-disable-next-line no-useless-escape
  const Regex = new RegExp(/^https:\/\/(?:www\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,}\.[a-zA-Z]{2,}\b(:\/[\w#!:.?+=&%@!\-\/]*)?$/);

  // If the flag "initWithOneEmpty" has been passed in props,
  // and that there is not link passed, we force the display of
  // an empty to prevent user from an unnecssary click on "add".
  const shouldInitWithOneEmpty = initWithOneEmpty && links?.length === 0;
  const emptyLink = {
    title: undefined,
    url: undefined,
    ProjectId,
  };

  useEffect(() => {
    if (shouldInitWithOneEmpty) { setFieldValue('Links', [emptyLink]); }
  }, [shouldInitWithOneEmpty, emptyLink]);

  const handleAdd = () => {
    setFieldValue('Links', [...links, emptyLink]);
  };

  const validateOneLink = (link) => {
    let errors = {};
    if (!link.title && !link.url) {
      errors = {};
    }
    if (!link.title && link.url) {
      errors.title = 'Title is mandatory';
      // setErrors('Title is mandatory');
    }
    if (link.url && !Regex.test(link.url)) {
      errors.link = 'Correct syntax : https://www.domain.ext';
      // setErrors('Link is not valid. Correct syntax : https://www.domain.ext');
    }
    if (link.title && !link.url) {
      errors.link = 'url is mandatory';
    }
    return errors;
  };

  const validateLinks = (value) => {
    const errors = value.Links.map(validateOneLink);
    if (errors.length === 1 && isEmpty(errors[0])) {
      setValidation(false);
      return errors;
    } if (errors.length > 1) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < errors.length; i++) {
        if (!isEmpty(errors[i])) {
          setValidation(true);
          return errors;
        }
      }
      setValidation(false);
    }
    return errors;
  };

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item xs={6}>
        <label htmlFor="links" className={edit ? 'title' : 'labelsFormCreate'}>Links</label>
      </Grid>
      <Grid container justifyContent="flex-end" item xs={6}>
        {!edit && (
          <button
            type="button"
            className="button"
            onClick={handleAdd}
          >
            + Add another link
          </button>
        )}
      </Grid>
      <Grid item xs={12}>
        {links.map((link, index) => (
          <>
            <ProjectLink
              key={index}
              index={index}
              edit={edit}
              errors={validateLinks(values)}
              {...link}
            />
          </>
        ))}
      </Grid>
      {edit ? (
        <Box marginTop="1rem" marginBottom="40px">
          <ButtonCustom variant="small" event={handleAdd} text="Link" icon={<AddIcon />} />
        </Box>

      ) : '' }
    </Grid>
  );
};

LinksCreator.defaultProps = {
  links: [],
  initWithOneEmpty: true,
  edit: false,
  setValidation: () => {},
};

LinksCreator.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape()),
  index: PropTypes.number.isRequired,
  initWithOneEmpty: PropTypes.bool,
  edit: PropTypes.bool,
  setValidation: PropTypes.func,
};

ProjectLink.propTypes = {
  index: PropTypes.number.isRequired,
};

export default LinksCreator;
