/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const LabelCustom = ({ isMandatory, label }) => (
  <Box display="flex" className="formFieldLabel" gridGap="5px">
    <label htmlFor="ActivityDemand" className="labelsFormCreate">
      {label}
      { isMandatory
        ? (
          <span className="mandatoryInputs">
            {' '}
            *
          </span>
        ) : null }
    </label>
  </Box>
);

LabelCustom.propTypes = {
  isMandatory: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

LabelCustom.defaultProps = {
  isMandatory: false,
};

export default LabelCustom;
