import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MomentUtils from '@date-io/moment';
import {
  ThemeProvider, createTheme, makeStyles, withStyles,
} from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ShowOnRoles from '../utils/ShowOnRoles';
import IconType from './IconType';
import CreateProject from './creationproject/CreateProject';

const BootstrapInput = withStyles(() => ({
  input: {
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  selectRoot: {
    '&:focus': {
      backgroundColor: '#F4F4F4',
    },
  },
  selectAllMy: {
    padding: '0',
    backgroundColor: '#F4F4F4',
    fontSize: '32px',
    fontWeight: 700,
    '&:focus': {
      backgroundColor: 'red',
    },
  },
  steps: {
    maxWidth: 400,
    flexGrow: 1,
  },
  selectBudgetType: {
    display: 'flex',
    alignItems: 'center',
    width: '210px',
    padding: '8px 0px 8px 16px',
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    height: '40px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    width: '170px',
    padding: '8px 0px 8px 16px',
    borderRadius: '8px',
    color: 'black',
    backgroundColor: 'white',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    height: '40px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '8px',
    },
  },
  outlined: {
    padding: '10.5px 0 10.5px 0px',
    height: '40px',

  },
  labelProjectType: {
    width: '200px',
    margin: '-12px 0 0 0',
  },
  datePicker: {
    height: '36px',
    borderRadius: '8px',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
    fontFamily: 'Roboto',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    paddingLeft: '16px',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  leftHat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const DashboardHat = (props) => {
  const {
    setProjectTime, projectTime, projectTypeFilter, setProjectTypeFilter, statusFilter,
    setStatusFilter, start, setStart, end, setEnd, success, setSuccess,
    budgetTypeFilter, setBudgetTypeFilter, openModalCollaborators, setOpenModalCollaborators,
    setProjectId,
  } = props;
  const [projectsDisplayed, setProjectsDisplayed] = useState('My Projects');

  const classes = useStyles();

  const handleChangeProjectType = (event) => {
    setProjectTypeFilter(event.target.value);
  };
  const handleChangeBudgetType = (event) => {
    setBudgetTypeFilter(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setStatusFilter(event.target.value);
  };
  const handleChangeStart = (date) => {
    setStart(date);
  };
  const handleChangeEnd = (date) => {
    setEnd(date);
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#FFB81C',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          '&$error': {
            '& $notchedOutline': {
              borderColor: 'red',
            },
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#FFB81C',
          },
          '&$focused $notchedOutline': {
            borderColor: '#FFB81C',
          },
        },
        notchedOutline: {
          borderRadius: '8px',
        },
        input: {
          padding: '1px 8px',
          fontSize: '1rem',
        },
      },
      MuiInputLabel: {
        outlined: {
          '&$focused': {
            color: '#FFB81C',
          },
        },
      },
    },
  });

  const removeFilters = () => {
    setProjectTypeFilter('');
    setStatusFilter('');
    setStart('');
    setEnd('');
    setBudgetTypeFilter('');
  };

  const atLeastOneFilterIsSelected = (statusFilter !== '') || (projectTypeFilter !== '') || (start !== '') || (end !== '') || (budgetTypeFilter !== '');

  return (
    <div>
      <div id="dashboardHat">
        <div className={classes.leftHat}>
          <FormControl>
            <Select
              classes={{ root: classes.selectRoot }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              className={classes.selectAllMy}
              labelId="demo-simple-select-label"
              id="selectMyAll"
              value={projectsDisplayed}
              onChange={(event) => setProjectsDisplayed(event.target.value)}
            >
              <MenuItem className="items" value="My Projects">My Projects</MenuItem>
              <MenuItem className="items" value="Projects">All Projects</MenuItem>
            </Select>
          </FormControl>
          <div id="boxTimeButtons">
            <button
              className={projectTime === '?ProjectTime=all' ? 'timeButtonFocus' : 'timeButtons'}
              type="button"
              onClick={() => {
                setProjectTime('?ProjectTime=all');
              }}
            >
              All

            </button>
            <button className={projectTime === '?ProjectTime=current' ? 'timeButtonFocus' : 'timeButtons'} type="button" onClick={() => setProjectTime('?ProjectTime=current')}>OnGoing</button>
            <button className={projectTime === '?ProjectTime=future' ? 'timeButtonFocus' : 'timeButtons'} type="button" onClick={() => setProjectTime('?ProjectTime=future')}>Future</button>
            <button className={projectTime === '?ProjectTime=past' ? 'timeButtonFocus' : 'timeButtons'} type="button" onClick={() => setProjectTime('?ProjectTime=past')}>Finished</button>
            <button className={projectTime === '/archived' ? 'timeButtonFocus' : 'timeButtons'} type="button" onClick={() => setProjectTime('/archived')}>Archived</button>
          </div>
        </div>
        <div id="filtersDashboard">

          <ShowOnRoles allowedRoleIds={[1, 2, 4]}>
            <CreateProject
              success={success}
              setSuccess={setSuccess}
              openModalCollaborators={openModalCollaborators}
              setOpenModalCollaborators={setOpenModalCollaborators}
              setProjectId={setProjectId}
            />
          </ShowOnRoles>

          <div id="filtersBox">
            {
              atLeastOneFilterIsSelected
                ? (
                  <button
                    type="button"
                    id="removeFilters"
                    style={{ cursor: 'pointer' }}
                    onClick={removeFilters}
                  >
                    Clear filters
                  </button>
                )
                : ''
            }
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ThemeProvider theme={customTheme}>
                <KeyboardDatePicker
                  id="start"
                  name="start"
                  label="Start Date"
                  value={start}
                  placeholder="dd-mm-yyyy"
                  autoOk
                  format="DD-MM-yyyy"
                  size="small"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChangeStart}
                  className="dashboardInput"
                  inputlabelprops={{
                    shrink: true,
                    backgroundColor: '#FDB71A',
                  }}
                  sx={{
                    backgroundColor: '#FDB71A',
                  }}
                  invalidDateMessage=""
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ThemeProvider theme={customTheme}>
                <KeyboardDatePicker
                  id="end"
                  name="end"
                  label="End Date"
                  value={end}
                  placeholder="dd-mm-yyyy"
                  autoOk
                  format="DD-MM-yyyy"
                  size="small"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChangeEnd}
                  className="dashboardInput"
                  inputlabelprops={{
                    shrink: true,
                  }}
                  invalidDateMessage=""
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
            <Select
              name="status"
              id="status"
              value={statusFilter}
              onChange={handleChangeStatus}
              displayEmpty
              defaultValue=""
              classes={{ select: classes.select, outlined: classes.outlined }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                elevation: 0,
              }}
              input={<BootstrapInput />}
            >
              <MenuItem name="status" value="">
                Project Status
              </MenuItem>
              <MenuItem value="green">
                <FiberManualRecordIcon className="pointFilter" id="statusGreen" />
                Green
              </MenuItem>
              <MenuItem value="orange">
                {' '}
                <FiberManualRecordIcon className="pointFilter" id="statusOrange" />
                Orange
              </MenuItem>
              <MenuItem value="red">
                {' '}
                <FiberManualRecordIcon className="pointFilter" id="statusRed" />
                Red
              </MenuItem>
            </Select>

            <Select
              name="project_type"
              id="project_type"
              value={projectTypeFilter}
              onChange={handleChangeProjectType}
              displayEmpty
              defaultValue=""
              input={<BootstrapInput />}
              classes={{
                select: classes.select,
                outlined:
                classes.outlined,
                underline: classes.underline,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                elevation: 0,
              }}
            >
              <MenuItem name="project_type" value="">Project Type</MenuItem>
              <MenuItem value="Development">
                {' '}
                <IconType type="Development" select="select" />
                Development
              </MenuItem>
              <MenuItem value="Design">
                <IconType type="Design" select="select" />
                Design
              </MenuItem>
              <MenuItem value="Cybersecurity">
                <IconType type="Cybersecurity" select="select" />
                Cybersecurity
              </MenuItem>
              <MenuItem value="Cloud">
                <IconType type="Cloud" select="select" />
                Cloud
              </MenuItem>
            </Select>

            <Select
              name="budget_type"
              id="budget_type"
              value={budgetTypeFilter}
              onChange={handleChangeBudgetType}
              displayEmpty
              defaultValue=""
              input={<BootstrapInput />}
              classes={{
                select: classes.selectBudgetType,
                outlined: classes.outlined,
                underline: classes.underline,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                elevation: 0,
              }}
            >
              <MenuItem name="budget_type" value="">Budget Type</MenuItem>
              <MenuItem value="Not defined">
                {' '}
                <IconType type="Not defined" select="select" />
                Not defined
              </MenuItem>
              <MenuItem value="Time and material">
                <IconType type="Time and material" select="select" />
                Time and material
              </MenuItem>
              <MenuItem value="Work Package">
                <IconType type="Work Package" select="select" />
                Work Package
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>

    </div>
  );
};

DashboardHat.defaultProps = {
  start: null,
  end: null,
  setOpenModalCollaborators: () => {},
  setProjectId: () => {},
};

DashboardHat.propTypes = {
  setProjectTime: PropTypes.func.isRequired,
  projectTime: PropTypes.string.isRequired,
  projectTypeFilter: PropTypes.string.isRequired,
  setProjectTypeFilter: PropTypes.func.isRequired,
  statusFilter: PropTypes.string.isRequired,
  budgetTypeFilter: PropTypes.string.isRequired,
  setBudgetTypeFilter: PropTypes.func.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  openModalCollaborators: PropTypes.bool.isRequired,
  setOpenModalCollaborators: PropTypes.func,
  setProjectId: PropTypes.func,
  setStart: PropTypes.func.isRequired,
  setEnd: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  setSuccess: PropTypes.func.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
};

export default DashboardHat;
