/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-body-style */
import MomentUtils from '@date-io/moment';
import {
  Box, Button,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { extendMoment } from 'moment-range';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { api } from '../../api/api';
import { risksAdded } from '../../redux/RiskSlice';
import { ReactComponent as Oops } from '../../ressources/oops.svg';
import ProjectDetailsLoader from '../loaders/ProjectDetailsLoader';
import CollaboratorsList from './CollaboratorsList';
import ProjectDetails from './ProjectDetails';
import ProjectLayout from './ProjectLayout';
import ProjectStatus from './ProjectStatus';

const Project = () => {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [toolsSelected, setToolsSelected] = useState();
  const [technosSelected, setTechnosSelected] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [colabs, setColabs] = useState({});
  const [colorSelected, setColorSelected] = useState(project.color);
  const [updateForecasting, setUpdateForecasting] = useState(false);
  // used to track whether the user has modified the 'Budget Type' field
  // and thus, to check, if the snapshot should be deleting, when submitting the changes
  const [deleteSnapshots, setDeleteSnapshots] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const [clientContacts, setClientContacts] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`api/projects/${id}`)
      .then((res) => res.data)
      .then((data) => setProject(data))
      .then(() => setToolsSelected(project.Tools))
      .then(() => setTechnosSelected(project.Technos))
      .then(() => setUsersSelected(project.Team))
      .then(() => setClientContacts(project.Client?.ClientContacts ?? false))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.error('Cant get a project', err);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [id, edit]);

  useEffect(() => {
    api
      .get(`api/risks?ProjectId=${id}`)
      .then((res) => res.data)
      .then((data) => {
        dispatch(risksAdded(data.filter((item) => item.type === 'Risk')));
      });
  }, []);

  function softDeleteSnapshots() {
    let snapshotsIds;
    api
      .get(`api/snapshots?ProjectId=${id}`)
      .then((res) => res.data)
      .then((resData) => {
        if (resData && resData.length > 0) {
          snapshotsIds = resData.map((snapshot) => snapshot.id);
          // we perform a soft-delete (i.e.: the records remain in the database, their 'deletedAt'
          // field is set to the current datetime)
          api.delete('api/snapshots', { data: snapshotsIds });
        }
      });
  }

  function softDeleteAndRestoreSnapshots(projectData) {
    // if the user has modified the 'Budget Type' field, then, we delete the
    // existing snapshots (if any)
    if (deleteSnapshots) {
      setDeleteSnapshots(false);
      softDeleteSnapshots();
      // now, we restore (i.e.: unset the 'deletedAt' field) the previously
      // soft-deleted records (if any)
      api.patch(`api/snapshots?projectId=${id}&budgetType=${projectData.time_budget_type}`);
    }
  }

  useEffect(() => {
    if (project.managerId) {
      api
        .get(`api/forecasting?ProjectId=${id}&ManagerId=${project.managerId}`)
        .then((res) => res.data)
        .then((data) => setColabs(data))
        .then(setUpdateForecasting(true));
    }
  }, [project, updateForecasting]);

  const momentRange = extendMoment(moment);
  const datesRangeToForecastings = colabs.length > 0 && colabs.reduce((acc, cur) => {
    const start = moment(cur.startDate, 'YYYY-MM-DD');
    const end = moment(cur.endDate, 'YYYY-MM-DD');
    const range = momentRange.range(start, end);
    const arrayRange = Array.from(range.by('days')).map((m) => m.format('YYYY-MM-DD')).filter((item) => {
      const date = moment(item).format('ddd');
      return date !== 'Sat' && date !== 'Sun';
    });
    if (arrayRange.length > 0) {
      for (let i = 0; i <= arrayRange.length - 1; i++) {
        acc.push({
          ...cur,
          date: arrayRange[i],
        });
      }
    } else {
      acc.push({
        ...cur,
      });
    }
    return acc;
  }, []);

  const submitting = (values, actions) => {
    const url = `api/projects?ProjectId=${id}`;
    const data = { ...values };
    data.ClientId = values.Client.id;
    data.Tools = toolsSelected.map((tool) => tool.id);
    data.Technos = technosSelected.map((techno) => techno.id);
    data.Links = values.Links.filter((item) => typeof item.url !== 'undefined');
    data.contactId = data.Contact ? data.Contact.id : null;
    data.deliveryLeadId = data.DeliveryLead ? data.DeliveryLead.id : null;
    data.color = colorSelected;
    data.Users = usersSelected;
    data.Client.ClientContacts = { clientId: values.Client.id, ...clientContacts };
    if (!data.Client.ClientContacts.full_name) {
      data.Client.ClientContacts.full_name = '';
    }
    softDeleteAndRestoreSnapshots(data);

    api
      .patch(url, data)
      .then(() => actions.setSubmitting(false))
      .then(() => setEdit(false))
      .catch((error) => {
        console.error('Something went wrong when trying to update the project - ', error);
      });
  };
  if (!isEmpty(project) && !isLoading) {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{
            ...project,
            Team: usersSelected,
            Client: { ...project.Client, ClientContacts: clientContacts },
          }}
          onSubmit={submitting}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, values, setFieldValue }) => {
            return (
              <Form>
                <ProjectLayout
                  leftSection={!isLoading ? (
                    <ProjectDetails
                      budgetType={project.time_budget_type}
                      project={project}
                      valueTab={valueTab}
                      setValueTab={setValueTab}
                      setProject={setProject}
                      edit={edit}
                      setEdit={setEdit}
                      setFieldValue={setFieldValue}
                      toolsSelected={toolsSelected}
                      technosSelected={technosSelected}
                      setToolsSelected={setToolsSelected}
                      setTechnosSelected={setTechnosSelected}
                      setColorSelected={setColorSelected}
                      colorSelected={colorSelected}
                      setDeleteSnapshots={setDeleteSnapshots}
                      handleSubmit={handleSubmit}
                      setUsersSelected={setUsersSelected}
                      usersSelected={usersSelected}
                      clientId={values.Client.id}
                      clientContacts={clientContacts}
                      setClientContacts={setClientContacts}
                      updateForecasting={updateForecasting}
                    />
                  ) : <CircularProgress />}
                  rightSectionList={!isLoading ? [<ProjectStatus
                    key="projectStatus"
                    status={project.status}
                    progression={project.progression}
                    endDate={project.end_date}
                    startDate={project.start_date}
                    chanceWinning={project.chance_winning}
                    edit={edit}
                    values={values}
                    setFieldValue={setFieldValue}
                    stepSale={project.step_sale}
                  />, <CollaboratorsList
                    key="collaboratorsList"
                    edit={edit}
                    ManagerId={project.ManagerId}
                    fullyStaffed={project.fully_staffed.toString()}
                    // forecastings={colabs}
                    forecastings={datesRangeToForecastings}
                    ContactId={project.ContactId}
                    projectId={project.id}
                    setUpdateForecasting={setUpdateForecasting}
                    updateForecasting={updateForecasting}
                    openModal={location.state ? location.state.openModal : false}
                  />] : [<CircularProgress key="circularProgress" />]}
                />
              </Form>
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    );
  }
  if (isLoading) {
    return (
      <>
        <ProjectDetailsLoader />
      </>
    );
  }
  if (isEmpty(project)) {
    return (
      <>
        <Box className="paper">
          <Typography className="textError">
            Ooops!
          </Typography>
          <Oops />
          <Typography className="textError">
            The page you requested doesn´t exist.
          </Typography>
          <br />
          <Link to="/">
            <Button className="saveBtn">
              Go back
            </Button>
          </Link>
        </Box>
      </>
    );
  }

  return undefined;
};

export default Project;
