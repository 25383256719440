import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: (props) => props.widthModal,
    height: (props) => props.heightModal,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#4B4E4A #ffffff',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px',
    padding: '32px',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
  },
  iconButtonClear: {
    padding: '0px',
    width: '32px',
    height: '32px',
    color: '#666666',
  },
}));

export default useStyles;
