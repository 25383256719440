/* eslint-disable react/prop-types */
import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import useStyles from './style';

const CustomSelect = ({
  field, form: { touched, errors },
  currentStatus, ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <Select
        variant="outlined"
        name={field.name}
        displayEmpty
        classes={{
          select: classes.select,
          outlined: classes.outlined,
          selectMenu: classes.selectMenu,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          label: 'toto',
        }}
        {...field}
        {...props}
      >
        <MenuItem value="" disabled>
          {props.placeholder}
        </MenuItem>
        {props.elements.map((i) => (
          <MenuItem value={i.value}>
            {i.item}
          </MenuItem>
        ))}
      </Select>
      {touched && errors && touched[field.name]
      && errors[field.name] ? <div className="error">{errors[field.name]}</div> : null}
    </>
  );
};

const SelectFormik = (props) => (
  <Field
    component={CustomSelect}
    {...props}
  />
);

SelectFormik.defaultProps = {
  placeholder: '',
  elements: [],
  validate: () => {},
  renderValue: '',
};

SelectFormik.propTypes = {
  placeholder: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  renderValue: PropTypes.func,
};

export default SelectFormik;
