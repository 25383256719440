/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ButtonCustom from '../../commonComponents/ButtonCustom/ButtonCustom';
import ModalCustom from '../../commonComponents/ModalCustom/ModalCustom';
// import { setOpenModalCollaborators } from '../../redux/CollaboratorSlice';

const useStyles = makeStyles(() => ({
  deleteButton: {
    color: '#4B4E4A',
    width: '30px',
    height: '30px',
    padding: '24px',
    opacity: '70%',
    marginLeft: '1.5rem',
  },
  buttonRemove: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    margin: '16px 0 0 7px',
  },
}));

const ManageCollaborators = (props) => {
  const {
    setOpenModalCollaborators, openModalCollaborators, projectId = undefined,
  } = props;
  const classes = useStyles();
  const location = useLocation();

  const handleOpen = () => {
    setOpenModalCollaborators(true);
  };

  const handleClose = () => {
    setOpenModalCollaborators(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <IconButton
          disableFocusRipple
          disableRipple
          aria-label="Add Collaborators"
          className={classes.buttonRemove}
          onClick={handleOpen}
          size="small"
        />
      </Box>
      <ModalCustom
        handleClose={handleClose}
        open={openModalCollaborators}
        width="540px"
        title="Add collaborators and manage intensity now?"
      >
        <Box display="flex" gridGap="3rem" justifyContent="center">
          <NavLink to={{ pathname: `/projects/${projectId}`, state: { openModal: true } }}>
            <ButtonCustom variant="large" text="Add Collaborators" />
          </NavLink>
          <NavLink to={{ pathname: `/projects/${projectId}` }}>
            <ButtonCustom
              isSimple
              variant="large"
              text="Later"
              border="1px solid #001F33"
            />
          </NavLink>
        </Box>
      </ModalCustom>
    </>
  );
};

ManageCollaborators.defaultProps = {
  projectId: undefined,
};

ManageCollaborators.propTypes = {
  openModalCollaborators: PropTypes.bool.isRequired,
  projectId: PropTypes.number,
  setOpenModalCollaborators: PropTypes.func.isRequired,
};

export default ManageCollaborators;
