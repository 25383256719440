import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes, { shape } from 'prop-types';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Box, Divider, Typography } from '@material-ui/core';
import { ErrorMessage, useFormikContext } from 'formik';
import RadioFormik from '../../commonComponents/RadioFormik/RadioFormik';
import NumberFieldFormik from '../../commonComponents/NumberFieldFormik/NumberFieldFormik';
import SelectFormik from '../../commonComponents/SelectFormik/SelectFormik';
import DeleteForecasting from './DeleteForecasting';
import { api } from '../../api/api';
import { multipleDateRangeOverlaps } from '../../utils/dateUtils';
import DatePickerFormik from '../../commonComponents/DatePickerFormik/DatePickerFormik';

const useStyles = makeStyles(() => ({
}));

const EditOneRange = (props) => {
  const classes = useStyles();
  const {
    index, key, remove, setList, setUpdateForecasting, id, dateRanges, setOverlap,
  } = props;

  const removeForecastings = (value) => {
    const forecast = value.forecastings.find((item) => item.uuid === id);
    api.delete('api/forecasting', {
      data: {
        ManagerId: forecast.ManagerId,
        UserId: forecast.UserId,
        ProjectId: forecast.ProjectId,
        startDate: forecast.startDate,
        endDate: forecast.endDate,
      },
    }).then(() => {
      setList(true);
      setList(false);
      setUpdateForecasting(true);
      setUpdateForecasting(false);
    });
  };

  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  useEffect(() => {
    multipleDateRangeOverlaps(dateRanges);
    setOverlap(multipleDateRangeOverlaps(dateRanges));
  }, [dateRanges]);

  return (
    <Box className="oneNewForecasting" display="flex" flexDirection="column" gridGap="0.5rem" marginTop={index !== 0 ? '1rem' : 0}>
      <Box>
        <Box gridGap="4px" width="100%">
          <Typography className={classes.labels}>Absence</Typography>
          <SelectFormik
            handleChange={(e) => {
              const { value } = e.target;
              if (value > 0) {
                setFieldValue(`forecastings.${index}.absence`, value);
                setFieldValue(`forecastings.${index}.intensity`, 0);
                setFieldValue(`forecastings.${index}.ProjectId`, undefined);
                setFieldValue(`forecastings.${index}.roleInProject`, '0');
              } else setFieldValue(`forecastings.${index}.absence`, value);
            }}
            name={`forecastings.${index}.absence`}
            elements={[{ value: 0, item: 'No absence' }, { value: 1, item: 'Vacation' }, { value: 2, item: 'Sick' }]}
            fullWidth
            size="small"
          />
        </Box>
      </Box>
      <Box>
        <Box display="flex" gridGap="4px">
          <Typography className={classes.labels}>Date range</Typography>
          <span className="mandatoryInputs">*</span>
        </Box>
        <Box display="flex" alignItems="center" gridGap="4px" marginBottom="8px" justifyContent="space-between">
          <DatePickerFormik
            name={`forecastings.${index}.startDate`}
            onChangeValue={(value) => {
              setFieldTouched(`forecastings.${index}.startDate`, true);
              setFieldValue(`forecastings.${index}.startDate`, value); // TODO: needed?
            }}
          />
          <TrendingFlatIcon classes={{ root: classes.TrendingFlatIcon }} />
          <DatePickerFormik
            name={`forecastings.${index}.endDate`}
            onChangeValue={(value) => {
              setFieldTouched(`forecastings.${index}.endDate`, true);
              setFieldValue(`forecastings.${index}.endDate`, value); // TODO: needed?
            }}
          />
        </Box>
        <ErrorMessage
          name={`forecastings.${index}.startDate`}
          component="div"
          className="field-error"
        />
      </Box>

      {values.forecastings[index].absence === 0 ? (
        <>
          <Box>
            <Box display="flex" gridGap="4px">
              <Typography className={classes.labels}>Intensity (%)</Typography>
              <span className="mandatoryInputs">*</span>
            </Box>
            <NumberFieldFormik
              name={`forecastings.${index}.intensity`}
              onChange={(e) => {
                const { value } = e.target;
                setFieldValue(`forecastings.${index}.intensity`, parseInt(value, 10));
              }}
            />
            <ErrorMessage
              name={`forecastings.${index}.intensity`}
              component="div"
              className="field-error"
            />
          </Box>

          <Box>
            <Typography className={classes.labels}>Role</Typography>
            <RadioFormik
              name={`forecastings.${index}.roleInProject`}
              onChange={(e) => {
                const { value } = e.target;
                setFieldValue(`forecastings.${index}.roleInProject`, value);
              }}
              elements={[{ value: '0', name: 'None' }, { value: '1', name: 'Product Owner' }, { value: '2', name: 'Scrum Master' }]}
            />
          </Box>

          <Box marginTop="1rem">
            <Box display="flex" gridGap="4px">
              <Typography className={classes.labels}>Hourly Rate (€)</Typography>
              <span className="mandatoryInputs">*</span>
            </Box>
            <NumberFieldFormik
              name={`forecastings.${index}.hourlyRate`}
              onChange={(e) => {
                const { value } = e.target;
                setFieldValue(`forecastings.${index}.hourlyRate`, parseInt(value, 10));
              }}
            />
            <ErrorMessage
              name={`forecastings.${index}.hourlyRate`}
              component="div"
              className="field-error"
            />
          </Box>

        </>
      ) : ''}
      <DeleteForecasting
        filterForecastings={remove}
        removeForecastings={removeForecastings}
        key={key}
        values={values}
      />
      <Divider />
    </Box>

  );
};

EditOneRange.propTypes = {
  remove: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setList: PropTypes.bool.isRequired,
  setUpdateForecasting: PropTypes.bool.isRequired,
  dateRanges: PropTypes.arrayOf(shape()).isRequired,
  setOverlap: PropTypes.func.isRequired,
};

export default EditOneRange;
