import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  select: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  outlined: {
    height: '40px',
  },
  selectMenu: {
    padding: '0 0 0 16px',
  },
}));

export default useStyles;
