import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Capex from './capex/Capex';
import OpexTable from './OpexTable ';
import TotalIncomeTable from './TotalIncomeTable';
import TotalExpensesTable from './TotalExpensesTable';
import { convertCurrency } from '../../../utils/convertCurrency';
// import TableLoader from '../../loaders/TableLoader';
import { api } from '../../../api/api';

const useStyles = makeStyles(() => ({
  createExpenseContainer: {
    height: '200px',
    position: 'relative',
    border: '2px dashed',
    margin: '30px',
  },
  addCapexButon: {
    backgroundColor: 'rgba(55, 58, 54, 0.05)',
    textTransform: 'none',
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '45%',
    transform: 'translateY(-50%)',
  },
  tableTitle: {
    marginBottom: '15px',
    float: 'left',
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    padding: '7px 3px 0px 3px',
  },
}));

const Finance = (props) => {
  const {
    ProjectId, edit, currency, convertCurrencyValue,
  } = props;

  const [updateExpenses, setUpdateExpenses] = useState(false);
  // const [forecastings, setForecastings] = useState([]);
  const [capexExpenses, setCapexExpenses] = useState([]);
  const [capexTableLoader, setCapexTableLoader] = useState(true);
  const [opexLoader, setOpexLoader] = useState(true);
  const [totalPriceProject, setTotalPriceProject] = useState([]);
  const classes = useStyles();

  const risks = useSelector((state) => state.risksState.risks);

  // Here we get and manage OPEX expenses
  const forecastingsRedux = useSelector((state) => state.forecastingsState.forecastings)
    .filter((forecasting) => forecasting.ProjectId === ProjectId
    && moment(forecasting.date).isSameOrBefore());

  useEffect(() => {
    setOpexLoader(true);
    setOpexLoader(false);
  }, []);

  const impactRiskEuros = risks.filter((risk) => risk.hasImpacted === 'Yes' && risk.potImpactType === '€')
    .reduce((acc, cur) => {
      acc.push(parseInt(cur.potentialImpact, 10));
      return acc;
    }, []).reduce((acc, cur) => acc + cur, 0);

  const impactRiskHours = risks.filter((risk) => risk.hasImpacted === 'Yes' && risk.potImpactType === 'hrs')
    .reduce((acc, cur) => {
      acc.push(parseInt(cur.potentialImpact, 10));
      return acc;
    }, []).reduce((acc, cur) => acc + cur, 0);

  // Here a class UserOpex
  function UserOpex() {
    return ({
      fullName: null,
      daysWorked: null,
      hourlyRate: null,
      id: null,
      date: null,
      projectEndDate: null,
      projectStartDate: null,
    });
  }

  const forecastingsGroupedByUsers = Object.values(groupBy(forecastingsRedux, 'UserId')).flatMap((forecasting) => forecasting);
  const arrayUsersOpex = forecastingsGroupedByUsers.reduce((acc, forecasting) => {
  // Here instance of class to be able to push some userOpex to array
    const userOpex = new UserOpex();
    Object.assign(
      userOpex, {
        fullName: `${forecasting.User.first_name} ${forecasting.User.last_name}`,
        daysWorked: ((forecasting.intensity * 1) / 100),
        hourlyRate: forecasting.hourlyRate,
        id: forecasting.id,
        date: moment(forecasting.date).format('YYYY-MM-DD'),
        projectEndDate: forecasting.Project.end_date,
        projectStartDate: forecasting.Project.start_date,
      },
    );
    acc.push(userOpex);
    return acc;
  }, []);

  const arrayToDisplay = arrayUsersOpex.reduce((acc, cur) => {
    acc.push({
      hourlyRate: cur.hourlyRate,
      fullName: cur.fullName,
      startDate: cur.startDate,
      endDate: cur.endDate,
      daysWorked: cur.daysWorked,
    });
    return acc;
  }, [])
    .reduce((acc, cur) => {
      const userPeriod = acc.find(
        (item) => item.fullName === cur.fullName,
      );
      if (userPeriod) {
        userPeriod.daysWorked += cur.daysWorked;
      } else {
        acc.push({
          fullName: cur.fullName,
          daysWorked: cur.daysWorked,
          hourlyRate: cur.hourlyRate,
        });
      }
      return acc;
    }, []);

  let totalEurosOpex = 0;
  arrayToDisplay.forEach((user) => {
    totalEurosOpex += (user.daysWorked * 8 * convertCurrencyValue(user.hourlyRate).toFixed(0));
  });

  useEffect(() => {
    if (totalEurosOpex > 0 || forecastingsRedux.length > 0) setOpexLoader(false);
    else setOpexLoader(true);
  }, []);
  // Here we get and manage the Capex expenses
  useEffect(() => {
    setCapexTableLoader(true);
    api
      .get(`api/expenses/${ProjectId}`)
      .then((res) => {
        setCapexExpenses(res.data);
        setCapexTableLoader(false);
      });
  }, [updateExpenses]);

  let totalCapex = 0;

  capexExpenses.forEach((exp) => {
    if (!exp.rebillCustomer) {
      totalCapex += (exp.quantity * convertCurrencyValue(exp.unitPrice).toFixed(0));
    }
  });
  // Here we want to get the price charged to the client. Total WP Price || Price Project T&M
  useEffect(() => {
    api
      .get(`api/snapshots?ProjectId=${ProjectId}`)
      .then((res) => {
        setTotalPriceProject(Object.values(groupBy(res.data, 'workPackageName')));
      });
  }, [updateExpenses]);

  let totalWPEuro = 0;
  totalPriceProject.forEach((el) => {
    totalWPEuro += convertCurrencyValue(el[0].totalPriceWP).toFixed(0);
  });

  const netProfit = (totalWPEuro - totalEurosOpex - totalCapex
    - convertCurrencyValue(impactRiskEuros).toFixed(0)).toFixed(0);
  return (
    <div id="leftSection3" className="sections">
      <Grid container spacing={2}>
        <Grid style={{ marginBottom: '1.5rem' }} item xs={12}>
          <Capex
            capexExpenses={capexExpenses}
            ProjectId={ProjectId}
            totalCapex={totalCapex}
            updateExpenses={updateExpenses}
            setUpdateExpenses={setUpdateExpenses}
            edit={edit}
            currency={currency}
            convertCurrencyValue={convertCurrencyValue}
          />
        </Grid>
      </Grid>
      {capexTableLoader === false && capexExpenses.length === 0 && !edit && (
        <Alert style={{ marginBottom: '1rem' }} severity="info">
          There is no CAPEX information. Go to edit mode to add some right away.
        </Alert>
      ) }

      {forecastingsRedux.length > 0 ? (
        <>
          <div>
            <OpexTable
              rows={arrayToDisplay}
              currency={currency}
              convertCurrencyValue={convertCurrencyValue}
            />
          </div>
        </>
      ) : (
        <>
          <Alert style={{ marginBottom: '1rem' }} severity="info">
            There is no OPEX information. Forecastings are required.
          </Alert>
        </>
      )}

      <div>
        {
          opexLoader === false && capexTableLoader === false && (
            <>
              <TotalIncomeTable
                rows={totalPriceProject}
                currency={currency}
                convertCurrencyValue={convertCurrencyValue}
              />

              <TotalExpensesTable
                totalCapex={totalCapex}
                totalOpex={totalEurosOpex}
                impactRiskEuros={impactRiskEuros}
                impactRiskHours={impactRiskHours}
                currency={currency}
                convertCurrencyValue={convertCurrencyValue}
              />
              {capexExpenses.length > 0 || forecastingsRedux.length > 0 ? (
                <>
                  <div id="netProfit" style={{ height: '20px', padding: '10px' }}>
                    <span className={classes.tableTitle} style={{ float: 'left', fontWeight: 'bold' }}>Net Profit</span>
                    <span className={classes.tableTitle} style={{ float: 'right', fontWeight: 'bold' }}>
                      {convertCurrency(currency)}
                      {netProfit}
                    </span>
                  </div>
                  <div id="profitMargin" style={{ height: '20px', padding: '10px' }}>
                    <span className={classes.tableTitle} style={{ float: 'left', fontWeight: 'bold' }}>Profit Margin</span>
                    <span className={classes.tableTitle} style={{ float: 'right', fontWeight: 'bold' }}>
                      {netProfit > 0 ? ((netProfit / totalWPEuro) * 100).toFixed(2) : 0}
                      %
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )
        }
      </div>
    </div>
  );
};

Finance.propTypes = {
  ProjectId: PropTypes.number.isRequired,
  edit: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  convertCurrencyValue: PropTypes.func.isRequired,
};

export default Finance;
