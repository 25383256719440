import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import CreateWPContent from './CreateWPContent';
import ModalCustom from '../../../commonComponents/ModalCustom/ModalCustom';

import ButtonCustom from '../../../commonComponents/ButtonCustom/ButtonCustom';

const BudgetWorkPackage = (props) => {
  const {
    workPakagesData, change, setChange, ProjectId, budgetType, edit,
  } = props;

  const [open, setOpen] = useState(false);

  let content;

  if (workPakagesData.lenght > 0) {
    content = <p>Hello la companie</p>;
  } else if (edit && !workPakagesData[0]) {
    content = (
      <div>
        <Box height="196px" border="1px dashed rgba(0, 0, 0, 0.5)" borderRadius="8px" display="flex" justifyContent="center" alignItems="center">
          <ButtonCustom
            isSimple
            text="Create Work Package"
            event={() => setOpen(true)}
            border="1px solid black"
            icon={<AddIcon />}
          />
        </Box>
        <ModalCustom
          handleClose={() => setOpen(false)}
          open={open}
          width="676px"
          title="Create workpackage"
        >
          <CreateWPContent
            ProjectId={ProjectId}
            setOpen={setOpen}
            change={change}
            setChange={setChange}
            budgetType={budgetType}
          />
        </ModalCustom>

      </div>
    );
  }
  return edit && workPakagesData.lenght === 0 ? <p>jpjp</p> : <div>{workPakagesData.lenght > 0 ? 'There is no Work Package created' : content}</div>;
};

BudgetWorkPackage.defaultProps = {
  change: false,
  workPakagesData: [],
};

BudgetWorkPackage.propTypes = {
  workPakagesData: PropTypes.arrayOf(PropTypes.shape()),
  setChange: PropTypes.func.isRequired,
  change: PropTypes.bool,
  edit: PropTypes.bool.isRequired,
  budgetType: PropTypes.string.isRequired,
  ProjectId: PropTypes.number.isRequired,
};

export default BudgetWorkPackage;
