import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { api } from '../../api/api';
import AutoCompleteFormik from '../../commonComponents/AutoCompleteFormik/AutoCompleteFormik';
import useStyles from './style';

const ContactPersonAutocomplete = ({ isCreation }) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const [employeesList, setEmployeesList] = useState([]);

  useEffect(() => {
    api
      .get('api/users')
      .then((res) => {
        setEmployeesList(res.data.items);
      });
  }, []);

  return (
    <AutoCompleteFormik
      options={employeesList}
      name="Contact"
      getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
      cleardisabled="true"
      placeholder="Select the contact person"
      value={values.Contact}
      onChange={(_, v) => setFieldValue('Contact', v.value ?? v)}
      id={isCreation ? 'autoCompleteCreateProject' : ''}
      shrink
      inputLabelProps={{ shrink: !isCreation }}
      style={{ width: '100%' }}
      classes={{
        inputFocused: classes.inputFocused,
        inputRoot: isCreation ? classes.inputRoot : '',
      }}
    />
  );
};

ContactPersonAutocomplete.defaultProps = {
  isCreation: false,
};

ContactPersonAutocomplete.propTypes = {
  isCreation: PropTypes.bool,
};

export default ContactPersonAutocomplete;
