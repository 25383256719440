/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Grid,
  Typography, Box, Checkbox, FormControlLabel,
} from '@material-ui/core';
import {
  ErrorMessage,
  Field,
  Form, Formik,
} from 'formik';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { api } from '../../../../api/api';
import CapexTable from './CapexTable';
import { convertCurrency } from '../../../../utils/convertCurrency';
import ModalCustom from '../../../../commonComponents/ModalCustom/ModalCustom';
import ButtonCustom from '../../../../commonComponents/ButtonCustom/ButtonCustom';
import ConfirmationModal from '../../../../dataComponents/ConfirmationModal/ConfirmationModal';
import TextFieldFormik from '../../../../commonComponents/TextFieldFormik/TextFieldFormik';
import SelectFormik from '../../../../commonComponents/SelectFormik/SelectFormik';
import TextAreaFormik from '../../../../commonComponents/TextAreaFormik/TextAreaFormik';
import NumberFieldFormik from '../../../../commonComponents/NumberFieldFormik/NumberFieldFormik';
import DatePickerFormik from '../../../../commonComponents/DatePickerFormik/DatePickerFormik';
import LabelCustom from '../../../../commonComponents/LabelCustom/LabelCustom';

const useStyles = makeStyles(() => ({
  createExpenseContainer: {
    height: '200px',
    position: 'relative',
    borderWidth: '1px',
    border: '1px rgb(231, 235, 240) solid',
    borderRadius: '10px',
    margin: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemsForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 0 28px 0',
  },
  notes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableTitle: {
    marginBottom: '15px',
    float: 'left',
  },
  moreHorizIcon: {
    float: 'right',
    cursor: 'pointer',
    marginTop: '30px',
    marginRight: '10px',
  },
  Update: {
    width: '268px',
  },
  gridItem: {
    margin: '5px',
  },
  tableLargeHeader: {
    background: 'none',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    borderBottom: 'none',
  },
}));

// Async validation function
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const validateAsync = (value) => sleep(50).then(() => {
  if (value?.length === 0 || typeof value === 'undefined') {
    return 'Mandatory Field';
  }
  if (value.length > 20) {
    return 'Cannot be longer than 20 characters';
  }
  if (value.length <= 1) {
    return 'Cant be shorter than 2 characters';
  }
  if (value < 0) {
    return 'Cannot be negative';
  }
  return undefined;
});

const CustomInput = (props) => {
  const {
    children, name, label, mandatory,
  } = props;
  return (
    <Box>
      <Box display="flex" className="formFieldLabel" gridGap="5px">
        <LabelCustom label={label} isMandatory={mandatory} />
      </Box>
      {children}
      {mandatory && (
      <ErrorMessage
        name={name}
        component="div"
        className="errorMessageCreateCollaborator"
      />
      )}
    </Box>
  );
};

const CreateCapex = (props) => {
  const {
    ProjectId, setUpdateExpenses, updateExpenses, currency, setOpenCreate,
  } = props;
  const classes = useStyles();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      ProjectId,
    };

    api.post('api/expenses', data)
      .then(() => setOpenCreate(false))
      .then(() => setUpdateExpenses(!updateExpenses));
  };

  return (
    <Formik
      initialValues={{
        expenseName: '', category: 'Other', date: null, quantity: 1, unitPrice: 1, rebillCustomer: false, extraInfos: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm, setFieldValue }) => (
        <Form className="formStyle">
          <Grid alignItems="stretch" container>
            <Grid className={classes.gridItem} item xs={12}>
              <CustomInput name="expenseName" label="Expense Name" mandatory>
                <TextFieldFormik
                  validate={validateAsync}
                  name="expenseName"
                  placeholder="Name"
                />
              </CustomInput>
            </Grid>
            <Grid className={classes.gridItem} container spacing={1} alignItems="stretch">
              <Grid item xs={6}>
                <CustomInput name="category" label="Category" mandatory>
                  <SelectFormik
                    validate={validateAsync}
                    name="category"
                    elements={['Other', 'IT', 'Material', 'Building', 'Travel', 'ByPass']
                      .map((el) => ({ value: el, item: el }))}
                    fullWidth
                    size="small"
                  />
                </CustomInput>
              </Grid>
              <Grid item xs={6}>
                <CustomInput name="date" label="Date" mandatory>
                  <DatePickerFormik
                    name="date"
                    className="fieldCreateCollaborator"
                    placeholder="dd-mm-yyyy"
                    onChangeValue={(value) => setFieldValue('date', value)}
                  />
                </CustomInput>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} container spacing={1} alignItems="stretch">
              <Grid item xs={4}>
                <CustomInput name="quantity" label="Quantity" mandatory>
                  <NumberFieldFormik
                    name="quantity"
                    validate={validateAsync}
                  />
                </CustomInput>
              </Grid>
              <Grid item xs={4}>
                <CustomInput name="unitPrice" label={`Unit Price (${convertCurrency(currency)})`} mandatory>
                  <NumberFieldFormik
                    name="unitPrice"
                    validate={validateAsync}
                  />
                </CustomInput>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Box display="inline-block">
                  <Field
                    type="checkbox"
                    name="rebillCustomer"
                    as={FormControlLabel}
                    control={<Checkbox />}
                    label="Re-Bill Customer?"
                  />
                  <span className="mandatoryInputs">*</span>
                </Box>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} item xs={12}>
              <CustomInput name="extraInfos" label="Notes">
                <TextAreaFormik
                  name="extraInfos"
                  label="Notes"
                  placeholder="Notes..."
                />
              </CustomInput>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="right" gridGap="0.5rem" marginTop="40px">
            <ButtonCustom isSimple event={() => setOpenCreate(false)} variant="large" text="Cancel" />
            <ButtonCustom
              variant="large"
              text="Save"
              event={submitForm}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const Capex = (props) => {
  const {
    capexExpenses,
    ProjectId,
    totalCapex,
    setUpdateExpenses,
    updateExpenses,
    edit,
    currency,
    convertCurrencyValue,
  } = props;

  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [capexIds, setCapexIds] = useState(capexExpenses.map((expense) => expense.id));

  const handleAddCapex = () => {
    setOpenCreate(true);
  };

  const projectId = ProjectId;

  const handleDeleteExpenses = () => {
    api.delete(`api/expenses?projectId=${projectId}`)
      .then(() => setUpdateExpenses(!updateExpenses))
      .then(() => setOpenDelete(false));
  };

  useEffect(() => {
    setCapexIds(capexExpenses.map((expense) => expense.id));
  }, [capexExpenses]);
  return (
    <>
      <Grid direction="column" container spacing={2}>
        {capexExpenses.length > 0 && (
          <Grid item={12}>
            <CapexTable
              rows={capexExpenses}
              edit={edit}
              updateExpenses={updateExpenses}
              setUpdateExpenses={setUpdateExpenses}
              currency={currency}
              convertCurrencyValue={convertCurrencyValue}
              ProjectId={ProjectId}
            />
          </Grid>
        )}
        <Grid style={{ paddingTop: 0 }} container item xs={12}>
          { capexExpenses.length > 0 && (
          <>
            {edit && (
            <Grid item xs={2}>
              <Button style={{ backgroundColor: 'rgba(55, 58, 54, 0.05)', textTransform: 'none' }}>
                <Typography onClick={handleAddCapex} variant="body2">
                  + Add
                </Typography>
              </Button>
            </Grid>
            )}
            <Grid container justifyContent="flex-end" item xs={edit ? 10 : 12}>
              <Typography style={{ fontWeight: 'bold' }} variant="overline">
                {convertCurrency(currency)}
                {totalCapex}
              </Typography>
            </Grid>

          </>
          )}
        </Grid>
      </Grid>
      {(
          capexExpenses.length === 0 ? (
            edit
              ? (
                <Box height="196px" border="1px dashed rgba(0, 0, 0, 0.5)" borderRadius="8px" display="flex" justifyContent="center" alignItems="center">
                  <ButtonCustom isSimple text="Create CAPEX Table" event={() => setOpenCreate(true)} border="1px solid black" icon={<AddIcon />} />
                </Box>
              )
              : undefined
          ) : undefined
      )}
      <ModalCustom
        handleClose={() => setOpenCreate(false)}
        open={openCreate}
        width="676px"
        title="Add CAPEX expense"
      >
        <CreateCapex
          ProjectId={ProjectId}
          updateExpenses={updateExpenses}
          setUpdateExpenses={setUpdateExpenses}
          currency={currency}
          setOpenCreate={setOpenCreate}
        />
      </ModalCustom>

      <ModalCustom
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
        width="676px"
        title="Delete Capex table?"
      >
        <ConfirmationModal
          onArchiveOrDelete={() => handleDeleteExpenses(capexIds)}
          onCancel={() => setOpenDelete(false)}
        />
      </ModalCustom>

    </>
  );
};

Capex.defaultProps = {
  edit: false,
  updateExpenses: false,
};

Capex.propTypes = {
  capexExpenses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ProjectId: PropTypes.number.isRequired,
  totalCapex: PropTypes.number.isRequired,
  setUpdateExpenses: PropTypes.func.isRequired,
  updateExpenses: PropTypes.bool,
  edit: PropTypes.bool,
  currency: PropTypes.string.isRequired,
};

export default Capex;
