/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  Box, Grid, TextField, Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ClientAutocomplete from '../../dataComponents/AutocompleteCreate/ClientAutocomplete';
import LabelCustom from '../../commonComponents/LabelCustom/LabelCustom';

const Step2 = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const {
    setValidation,
    shallNotPass,
    clientContacts,
    setClientContacts,
  } = props;

  const [clientValidation, setClientValidation] = useState(true);
  const [clientNameValidation, setClientNameValidation] = useState(false);
  const [emailChecker, setEmailChecker] = useState(false);
  const [phoneChecker, setPhoneChecker] = useState(false);

  const validateEmail = (email) => (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
  const validatePhone = (phone) => (!/^[+\d]{1,20}$/.test(phone));

  useEffect(() => {
    setClientValidation(true);
    setValidation(true);
  }, []);

  useEffect(() => {
    setClientValidation(typeof values.Client === 'undefined');
  }, [values?.Client]);

  useEffect(() => {
    if (typeof values.Client !== 'undefined') {
      setClientContacts({
        ...clientContacts,
        full_name: values.Client?.ClientContacts ? values.Client?.ClientContacts[0]?.full_name : undefined,
        email: values.Client?.ClientContacts ? values.Client?.ClientContacts[0]?.email : undefined,
        phone_number: values.Client?.ClientContacts ? values.Client?.ClientContacts[0]?.phone_number : undefined,
      });
    }
  }, [values?.Client?.ClientContacts]);

  useEffect(() => {
    if (values.Client && !clientContacts.full_name
      && (clientContacts.email || clientContacts.phone_number)) {
      setClientNameValidation(true);
    } else {
      setClientNameValidation(false);
    }
    if (typeof clientContacts.email === 'undefined' || clientContacts.email?.length === 0) {
      setEmailChecker(false);
    } else if (!validateEmail(clientContacts.email)) {
      setEmailChecker(false);
    } else {
      setEmailChecker(true);
    }
    if (typeof clientContacts.phone_number === 'undefined' || clientContacts.phone_number?.length === 0) {
      setPhoneChecker(false);
    } else if (typeof clientContacts.phone_number !== 'undefined' && !validatePhone(clientContacts.phone_number)) {
      setPhoneChecker(false);
    } else {
      setPhoneChecker(true);
    }
  }, [values.signed, values.Client, clientContacts]);

  useEffect(() => {
    if (typeof values.signed !== 'undefined'
    && typeof values.Client !== 'undefined'
    && !clientNameValidation
    && !clientValidation
    && !emailChecker
    && !phoneChecker) {
      setValidation(false);
    } else {
      setValidation(true);
    }
  }, [values, clientNameValidation, clientValidation, emailChecker, phoneChecker, clientContacts]);

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginBottom: '10px',
        width: '85%',
        margin: 'auto',
      }}
    >
      <Grid item xs={12}>
        <Box>
          <LabelCustom label="Client" isMandatory />
          <p className="helperText">If the client you are looking for does not exist, press enter to add a new one.</p>
          <ClientAutocomplete isCreation />
          <Typography variant="caption" className="errorMessageCreateCollaborator">
            {clientValidation && ('Required field')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <LabelCustom label="Contact Person" />
          <TextField
            style={{ width: '100%' }}
            name="Client.ClientContacts.full_name"
            value={typeof clientContacts.full_name !== 'undefined' ? clientContacts.full_name : ''}
            variant="outlined"
            placeholder="Type in a name"
            onChange={(e) => setClientContacts({
              ...clientContacts,
              full_name: e.target.value,
            })}
          />
          <Typography variant="caption" className="errorMessageCreateCollaborator">
            {clientNameValidation && ('Required field')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={6}>
          <Box marginRight="5px">
            <LabelCustom label="E-mail" />
            <TextField
              style={{ width: '100%' }}
              name="Client.ClientContacts.email"
              variant="outlined"
              placeholder="Type in an email adress"
              value={typeof clientContacts.email !== 'undefined' ? clientContacts.email : ''}
              onChange={(e) => setClientContacts({
                ...clientContacts,
                email: e.target.value,
              })}
            />
            <Typography variant="caption" className="errorMessageCreateCollaborator">
              {emailChecker && ('Invalid Email address')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box marginLeft="5px">
            <LabelCustom label="Phone number" />
            <TextField
              style={{ width: '100%' }}
              name="Client.ClientContacts.phone_number"
              variant="outlined"
              placeholder="Type in a phone number"
              value={typeof clientContacts.phone_number !== 'undefined' ? clientContacts.phone_number : ''}
              onChange={(e) => setClientContacts({
                ...clientContacts,
                phone_number: e.target.value,
              })}
            />
            <Typography variant="caption" className="errorMessageCreateCollaborator">
              {phoneChecker && ('Invalid Phone number')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

Step2.defaultProps = {
  setValidation: () => {},
  clientContacts: {},
};

Step2.propTypes = {
  setValidation: PropTypes.func,
  clientContacts: PropTypes.array,
};

export default Step2;
