import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import TextFieldFormik from '../../../commonComponents/TextFieldFormik/TextFieldFormik';

const ProjectName = (props) => {
  const { edit, projectName } = props;
  return (
    <div id={edit ? 'projectNameEdit' : 'projectName'}>
      {edit
        ? <TextFieldFormik name="project_name" label="Project name" placeholder="Your project name" maxLength={20} />
        : <p id="titleProjectDetailed">{projectName || <CircularProgress />}</p>}
    </div>
  );
};

ProjectName.propTypes = {
  edit: PropTypes.bool.isRequired,
  projectName: PropTypes.string.isRequired,
};

export default ProjectName;
