/* eslint-disable no-trailing-spaces */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { api } from '../../../api/api';
import ButtonCustom from '../../../commonComponents/ButtonCustom/ButtonCustom';
import TextFieldFormik from '../../../commonComponents/TextFieldFormik/TextFieldFormik';
import RadioFormik from '../../../commonComponents/RadioFormik/RadioFormik';
import SelectFormik from '../../../commonComponents/SelectFormik/SelectFormik';
import AutoCompleteFormik from '../../../commonComponents/AutoCompleteFormik/AutoCompleteFormik';
import { convertCurrency } from '../../../utils/convertCurrency';
import DatePickerFormik from '../../../commonComponents/DatePickerFormik/DatePickerFormik';
import LabelCustom from '../../../commonComponents/LabelCustom/LabelCustom';

const useStyles = makeStyles(() => ({
  modalCreateRisk: {
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFields: {
    width: '261px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  gridItem: {
    margin: '5px',
  },
  inputFocused: {
    marginLeft: '16px',
  },
}));

const validate = (values) => {
  const errors = {};

  if (values?.title?.length === 0 || !values?.title) {
    errors.title = 'Required Field';
  }
  if (values?.title?.length > 20) {
    errors.title = 'Title must be less than 20 characters';
  }
  if (!values?.ownerId || values.ownerId === '') {
    errors.ownerId = 'Required Field';
  }
  if (!values?.probability || values.probability === '') { 
    errors.probability = 'Required Field';
  }
  if (values?.impact === 0 || !values?.impact || values?.impact === '') {
    errors.impact = 'Required Field';
  }

  return errors;
};

const CustomInput = (props) => {
  const {
    children, name, label, mandatory,
  } = props;

  return (
    <Box>
      <Box display="flex" className="formFieldLabel" gridGap="5px">
        <LabelCustom label={label} isMandatory={mandatory} />
      </Box>
      {children}
      {mandatory && (
      <ErrorMessage
        name={name}
        component="div"
        className="errorMessageCreateCollaborator"
      />
      )}
    </Box>
  );
};

const CreateRisk = (props) => {
  const {
    ProjectId, setOpen, currency, userList, setTrigger, type,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const handleSubmit = (values) => {
    function calculateCriticity() {
      switch (values.impact) {
        case 'Low':
          if (values.probability === 'Very likely') {
            return 'Significant';
          }
          return 'Negligible';
        case 'Moderate':
          if (values.probability === 'Unlikely') {
            return 'Negligible';
          }
          if (values.probability === 'Possible' || values.probability === 'Probable') {
            return 'Significant';
          }
          return 'Critical';
        case 'High':
          if (values.probability === 'Unlikely') {
            return 'Significant';
          }
          if (values.probability === 'Possible' || values.probability === 'Probable') {
            return 'Critical';
          }
          return values.type === 'Risk' ? 'Unacceptable' : 'Essential';
        case 'Very high':
          if (values.probability === 'Unlikely' || values.probability === 'Possible') {
            return 'Critical';
          }
          return values.type === 'Risk' ? 'Unacceptable' : 'Essential';
        default:
          return '-';
      }
    }
    const data = {
      criticity: calculateCriticity(),
      projectId: parseInt(ProjectId, 10),
      ...values,
    };
    api({
      method: 'post',
      url: 'api/risks',
      data,
    }).then(() => {
      setOpen(false);
    })
      .then(() => setTimeout(() => {
        setTrigger(true);
      }, 1000))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error:', err);
      });
  };
  const initialValues = {
    type, title: '', impact: null, probability: null, dueDate: null, potentialImpact: '0', potImpactType: 'hrs', mitigation: '', dependencies: '', status: 'Open', ownerId: '', hasImpacted: 'Yes', action: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, submitForm }) => (
        <Form>
          <Grid id="forecastingsModal" alignItems="stretch" container>
            <Grid className={classes.gridItem} container spacing={2} alignItems="stretch">
              <Grid item xs={4}>
                <CustomInput name="type" label="Type" mandatory>
                  <SelectFormik
                    name="type"
                    elements={['Risk', 'Opportunity']
                      .map((el) => ({ value: el, item: el }))}
                    fullWidth
                    size="small"
                  />
                </CustomInput>
              </Grid>
              <Grid item xs={8}>
                <CustomInput name="title" label="Title" mandatory>
                  <TextFieldFormik
                    name="title"
                    placeholder="Title"
                  />
                </CustomInput>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} container spacing={2} alignItems="stretch">
              <Grid item xs={12}>
                <CustomInput name="impact" label="Impact" mandatory>
                  <RadioFormik
                    name="impact"
                    elements={[{ value: 'Low', name: 'Low' }, { value: 'Moderate', name: 'Moderate' }, { value: 'High', name: 'High' }, { value: 'Very high', name: 'Very high' }]}
                    onChange={(e) => setFieldValue('impact', e.target.value)}
                  />
                </CustomInput>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} container spacing={2} alignItems="stretch">
              <Grid item xs={12}>
                <CustomInput name="probability" label="Probability" mandatory>
                  <RadioFormik
                    name="probability"
                    elements={[{ value: 'Unlikely', name: 'Unlikely' }, { value: 'Possible', name: 'Possible' }, { value: 'Probable', name: 'Probable' }, { value: 'Very likely', name: 'Very likely' }]}
                    onChange={(e) => setFieldValue('probability', e.target.value)}
                  />
                </CustomInput>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} container spacing={2} alignItems="stretch">
              <Grid item xs={9}>
                <CustomInput name="potentialImpact" label="Potential Impact">
                  <TextFieldFormik
                    name="potentialImpact"
                    placeholder={`Potential impact on time (hours) or finance (${convertCurrency(currency)})`}
                  />
                </CustomInput>
              </Grid>
              <Grid item xs={3}>
                <CustomInput name="potImpactType" label="Impact type">
                  <SelectFormik
                    name="potImpactType"
                    renderValue={(p) => p}
                    elements={[{ item: 'Hours', value: 'hrs' }, { item: currency, value: convertCurrency(currency) }]}
                    fullWidth
                    size="small"
                  />
                </CustomInput>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} container spacing={2} alignItems="stretch">
              <Grid item xs={6}>
                {type === 'Risk' ? (
                  <CustomInput name="mitigation" label="Mitigation">
                    <TextFieldFormik
                      name="mitigation"
                      placeholder="Mitigation"
                    />
                  </CustomInput>
                ) : (
                  <CustomInput name="action" label="Action">
                    <TextFieldFormik
                      name="action"
                      placeholder="Action"
                    />
                  </CustomInput>
                )}
              </Grid>
              <Grid item xs={6}>
                <CustomInput name="ownerId" label="Owner" mandatory>
                  <AutoCompleteFormik
                    marginBottom="12px"
                    getOptionLabel={(option) => (option ? `${option.first_name} ${option.last_name}` : '')}
                    disableClearable
                    name="ownerId"
                    className={classes.fieldFormik}
                    classes={{
                      inputFocused: classes.inputFocused,
                    }}
                    onChange={(e, v) => {
                      setFieldValue('ownerId', v.id);
                    }}
                    placeholder="Owner"
                    options={userList}
                  />
                </CustomInput>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <CustomInput name="dependencies" label="Dependencies">
                  <TextFieldFormik
                    name="dependencies"
                    placeholder="Dependencies"
                  />
                </CustomInput>
              </Grid>
              <Grid item xs={6}>
                <CustomInput name="status" label="Status" mandatory>
                  <RadioFormik
                    validate={validate}
                    name="status"
                    elements={[{ value: 'Open', name: 'Open' }, { value: 'Closed', name: 'Closed' }]}
                    onChange={(e) => setFieldValue('status', e.target.value)}
                  />
                </CustomInput>
              </Grid>
            </Grid>
            <Grid className={classes.gridItem} container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <CustomInput name="dueDate" label="Due Date">
                  <DatePickerFormik
                    name="dueDate"
                    className="fieldCreateCollaborator"
                    placeholder="dd-mm-yyyy"
                    onChangeValue={(value) => setFieldValue('dueDate', value)}
                  />
                </CustomInput>
              </Grid>
              <Grid item xs={6}>
                <CustomInput name="hasImpacted" label={`Has this ${type.toLowerCase()} impacted the project?`}>
                  <RadioFormik
                    name="hasImpacted"
                    elements={[{ value: 'Yes', name: 'Yes' }, { value: 'No', name: 'No' }]}
                    onChange={(e) => setFieldValue('hasImpacted', e.target.value)}
                  />
                </CustomInput>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ paddingTop: '40px' }}
          >
            <ButtonCustom isSimple event={() => setOpen(false)} variant="large" text="Cancel" />
            <ButtonCustom variant="large" text="Save" event={submitForm} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateRisk.defaultProps = {
};

CreateRisk.propTypes = {
  ProjectId: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CreateRisk;
