import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import useStyles from './style';

const BootstrapInput = withStyles(() => ({
  input: {
  },
}))(InputBase);

const SelectTimeCustom = (props) => {
  const { timeUnit, setTimeUnit } = props;
  const classes = useStyles();

  return (
    <Select
      style={{ width: '120px', display: 'flex' }}
      value={timeUnit}
      onChange={(e) => setTimeUnit(e.target.value)}
      displayEmpty
      defaultValue=""
      classes={{ select: classes.select, outlined: classes.outlined }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      input={<BootstrapInput />}
    >
      <MenuItem value="day">
        <CalendarTodayOutlinedIcon className={classes.pointFilter} />
        Day
      </MenuItem>
      <MenuItem value="week">
        {' '}
        <CalendarTodayOutlinedIcon className={classes.pointFilter} />
        Week
      </MenuItem>
      <MenuItem value="month">
        {' '}
        <CalendarTodayOutlinedIcon className={classes.pointFilter} />
        Month
      </MenuItem>
    </Select>
  );
};

SelectTimeCustom.propTypes = {
  setTimeUnit: PropTypes.func.isRequired,
  timeUnit: PropTypes.string.isRequired,
};

export default SelectTimeCustom;
