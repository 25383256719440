/* eslint-disable react/prop-types */
import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const CustomTextField = ({
  field,
  form: { touched, errors },
  maxLength,
  ...props
}) => (
  <>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      inputLabelProps={{
        shrink: true,
      }}
      inputProps={{ maxLength }}
      {...field}
      {...props}
    />
    {errors.dueDate && touched.dueDate ? (
      <Box className="errorMessageCreateCollaborator">{errors.dueDate}</Box>
    ) : null}
  </>
);

const TextFieldFormik = (props) => (
  <Field
    component={CustomTextField}
    {...props}
  />
);

TextFieldFormik.defaultProps = {
  placeholder: '',
  label: '',
  validate: () => {},
};

TextFieldFormik.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

export default TextFieldFormik;
