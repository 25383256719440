import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as Oops } from '../ressources/oops.svg';

const NotFound = () => (
  <>
    <Box className="paper">
      <Typography className="textError" textAlign="center">
        Ooops!
      </Typography>
      <Oops />
      <Typography className="textError" textAlign="center">
        The page you requested doesn´t exist.
      </Typography>
      <br />
      <Link to="/">
        <Button className="saveBtn">
          Go back
        </Button>
      </Link>
    </Box>
  </>
);
export default NotFound;
