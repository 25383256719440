import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { api } from '../../api/api';
import ProjectNamesForecasting from './ProjectNamesForecasting';
import CollaboratorsForecasting from './CollaboratorsForecasting';
import { projectsAdded } from '../../redux/ProjectSlice';

const Forecasting = () => {
  const [projectsOneManager, setProjectsOneManager] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [membersOf, setMembersOf] = useState();
  const [all, setAll] = useState(false);
  const [pointer, setPointer] = useState(0);
  // const Location = useLocation();
  const dipatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.projectId !== undefined) {
      setPointer(location.state.projectId);
    }
  }, []);

  useEffect(() => {
    const today = moment(new Date());
    api
      .get('api/projects?order=project_name') // We only want current, future + pastproject - 5 weeks from today
      .then((res) => res.data.items)
      // .then((data) => dipatch(projectsAdded(data)))
      .then((data) => {
        setProjectsOneManager(data
          .filter((proj) => (moment(proj.end_date)
            .isAfter(today) && moment(today).isSameOrAfter(proj.start_date))
        || moment(today).isSameOrBefore(proj.start_date)
        || (proj.start_date == null && proj.end_date == null)
         || moment(proj.start_date).isSameOrAfter(today)
         || moment(today).subtract(5, 'w').isSameOrBefore(proj.start_date)));
        dipatch(projectsAdded(data));
      });
    // TODO CHANGE setMembersOf TO OTHER VALUE NAME
    if (location.state) setMembersOf(location.state.projectId);
  }, []);
  useEffect(() => {
    api
      .get('api/users')
      .then((res) => res.data.items)
      .then((data) => {
        setWorkers(data);
      });
  }, []);

  return (
    <div id="boxForecastingPage">
      <ProjectNamesForecasting
        pointer={pointer}
        setPointer={setPointer}
        all={all}
        setAll={setAll}
        projectsOneManager={projectsOneManager}
        setMembersOf={setMembersOf}
        membersOf={membersOf}
      />
      <CollaboratorsForecasting
        workers={workers}
        membersOf={membersOf}
        projectsOneManager={projectsOneManager}
        all={all}
        setAll={setAll}
      />
    </div>

  );
};

export default Forecasting;
