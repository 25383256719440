import React from 'react';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AutoCompleteFormik from './AutoCompleteFormik';
import useStyles from './style';

const AutoCompleteFormikCreate = ({
  name, onCreate, onChange, ...props
}) => {
  const goodVariable = `${name.toLowerCase()}_name`;
  const filter = createFilterOptions();
  const classes = useStyles();

  const handleChange = async (event, newValue) => {
    const toSet = newValue.isNew ? await onCreate(newValue) : newValue;
    onChange(name, toSet);
  };

  return (
    <AutoCompleteFormik
      onChange={handleChange}
      getOptionLabel={(option) => {
        if (typeof option === 'string') { return option; }
        if (option.isNew) { return `Add "${option[goodVariable]}"`; }
        if (option[goodVariable]) { return option[goodVariable]; }
        return option.title;
      }}
      getOptionSelected={(option, value) => option[goodVariable] === value[goodVariable]}
      filterOptions={(opts, params) => {
        const filtered = filter(opts, params);
        if (params.inputValue !== '') {
          const body = {};
          body[goodVariable] = params.inputValue;
          body.isNew = true;
          filtered.push(body);
        }
        return filtered;
      }}
      id={props.status === 'createProject' ? 'autoCompleteCreateProject' : ''}
      shrink
      inputLabelProps={{ shrink: props.status !== 'createProject' }}
      style={{ width: '100%' }}
      classes={{
        inputFocused: classes.inputFocused,
        inputRoot: props.status === 'createProject' ? classes.inputRoot : '',
      }}
      {...props}
    />

  );
};

AutoCompleteFormikCreate.defaultProps = {
  onCreate: () => {},
  status: '',
};

AutoCompleteFormikCreate.propTypes = {
  name: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.string,
};

export default AutoCompleteFormikCreate;
