/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  FormControlLabel,
  Radio, RadioGroup,
} from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const CustomRadio = ({
  field,
  ...props
}) => (
  <FormControlLabel
    control={(
      <Radio
        style={{ paddingTop: '0px', paddingBottom: '0px', paddingRight: '2px' }}
        color="primary"
      />
)}
    label="True"
    {...field}
    {...props}
  />
);

const RadioFormik = ({
  name, elements, onChange,
}) => (
  <RadioGroup>
    <Box display="flex">
      {elements.map((el) => (
        <Field
          type="radio"
          name={name}
          value={el.value}
          label={el.name}
          component={CustomRadio}
          onChange={onChange}
        />
      ))}
    </Box>
  </RadioGroup>
);

RadioFormik.defaultProps = {
  elements: [],
  onChange: undefined,
};

RadioFormik.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape().isRequired),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default RadioFormik;
