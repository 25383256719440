/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  colorProgression: {
    backgroundColor: (props) => props.colorStatus,
  },
  colorProgressionSec: {
    backgroundColor: (props) => props.colorStatusSec,
  },
  boxLabel: {
    width: '45px',
    fontSize: '16px',
    fontWeight: 500,
    color: 'rgba(112, 121, 135, 1)',
    marginLeft: '4px',
  },

});
function LinearProgressWithLabel(props) {
  const { value, typebudget, future } = props;
  const classes = useStyles();

  let goodToDisplay;
  if (future && (typebudget === 'Work Package')) goodToDisplay = 'WP';
  else if (future && (typebudget === 'Time and material')) goodToDisplay = 'TM';
  else if (future && (typebudget === 'Not defined')) goodToDisplay = 'ND';

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography>{goodToDisplay}</Typography>
        <Typography variant="body2" color="textPrimary" className={classes.boxLabel}>
          {value}
          {' '}
          %
        </Typography>
      </Box>
    </Box>
  );
}

const ProgressionChance = (props) => {
  const {
    progression, status, chanceWinning, endDate, startDate, budgetType,
  } = props;

  const todayDate = moment(Date.now());

  const onGoing = moment(startDate).isBefore(todayDate) && moment(todayDate).isBefore(endDate);
  const finished = (moment(endDate).isBefore(todayDate));

  let colorStatus;
  let colorStatusSec;
  if (status === 'green' && onGoing) {
    colorStatus = 'rgba(45, 200, 77, 0.7)';
    colorStatusSec = '#F0F0F0';
  } else if (status === 'orange' && onGoing) {
    colorStatus = 'rgba(253, 183, 26, 1)';
    colorStatusSec = '#F0F0F0';
  } else if (status === 'red' && onGoing) {
    colorStatus = 'rgba(218, 41, 28, 0.7)';
    colorStatusSec = '#F0F0F0';
  } else if (finished) {
    colorStatus = '#9B9B9C';
    colorStatusSec = '#9B9B9C';
  } else {
    colorStatus = '#518DEE';
    colorStatusSec = '#F0F0F0';
  }
  const classes = useStyles({ colorStatus, colorStatusSec });

  return (
    <div style={{ marginTop: '16px', marginLeft: '4px' }}>
      <div style={{ marginLeft: '-3px', width: '99%' }}>
        <LinearProgressWithLabel
          typebudget={budgetType}
          future={!onGoing && !finished}
          value={finished ? 100 : onGoing ? progression : chanceWinning}
          classes={{
            barColorPrimary: classes.colorProgression,
            colorPrimary: classes.colorProgressionSec,
          }}
        />
      </div>
    </div>
  );
};

ProgressionChance.defaultProps = {
  progression: null,
  chanceWinning: null,
  status: null,
  endDate: null,
  startDate: null,
  budgetType: '',
};
LinearProgressWithLabel.defaultProps = {
  value: null,
  typebudget: '',
};

ProgressionChance.propTypes = {
  progression: PropTypes.number,
  chanceWinning: PropTypes.number,
  status: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  budgetType: PropTypes.string,
};

LinearProgressWithLabel.defaultProps = {
  future: undefined,
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
  typebudget: PropTypes.string,
  future: PropTypes.bool,
};

export default ProgressionChance;
