import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { api } from '../../api/api';
import AutoCompleteFormikCreate from '../../commonComponents/AutoCompleteFormik/AutoCompleteFormikCreate';

const ClientAutocomplete = (props) => {
  const { isCreation } = props;

  const { values, setFieldValue } = useFormikContext();
  const [clientsList, setClientsList] = useState([]);

  useEffect(() => {
    api
      .get('api/clients')
      .then((res) => {
        setClientsList(res.data);
      });
  }, []);

  const handleCreate = (newValue) => api
    .post('api/clients', newValue)
    .then((response) => {
      setClientsList([...clientsList, response.data]);
      setFieldValue({ ...values, Client: response.data });
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

  return (
    <AutoCompleteFormikCreate
      name="Client"
      options={clientsList}
      disableClearable
      status={isCreation ? 'createProject' : ''}
      placeholder="Select a client"
      onCreate={handleCreate}
      onChange={setFieldValue}
      value={values.Client}
    />
  );
};

ClientAutocomplete.defaultProps = {
  isCreation: false,
};

ClientAutocomplete.propTypes = {
  isCreation: PropTypes.bool,
};

export default ClientAutocomplete;
