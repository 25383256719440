/* eslint-disable react/prop-types */
import React from 'react';
import { TextField } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const CustomNumberField = ({
  field,
  ...props
}) => (
  <>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      type="number"
      inputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputProps: {
          type: 'number',
          min: 1,
        },
      }}
      {...field}
      {...props}
    />
  </>
);

const NumberFieldFormik = (props) => (
  <Field
    component={CustomNumberField}
    {...props}
  />
);

NumberFieldFormik.defaultProps = {
  validate: () => {},
};

NumberFieldFormik.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

export default NumberFieldFormik;
